.card-transaction, .cash-transaction, .check-transaction, .card-declined-transaction {
  label {
    font-size: 1.000rem;
    margin-bottom: 5px;
    text-transform: capitalize;
    cursor: default;
  }
  h5 { color: $pdc-body-copy; }
  table {
    width: 100%;
    tr {
      td, th { color: $pdc-body-copy; }
    }
  }
  .grey-line { padding-bottom: 15px; }
}

.customer-information {
  margin-top: 20px;
  margin-bottom: 10px;
  ul {
    background-color: $white;
    border-radius: $global-radius;
    color: $pdc-body-copy;
    margin: 0;
    padding: 5px 15px 10px 15px;
    li {
      /*display: inline-block;
      font-size: 1.500rem;
      padding: 0;*/
      @include breakpoint (small only){
        font-size: 1.000rem;
      }
      &:first-child {
        margin-top: 5px;
        margin-right: 20px;
      }
      &:last-child {
        float: right;
        @include breakpoint (small only){
          float: none;
          padding-left: 20px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.customer-details, .transaction-details {
  tr {
    td {
      &:first-child:not(.defaultStyle) { font-weight: bold; }
    }
  }
}
