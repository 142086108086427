ul.messenger-theme-flat {
  background: transparent;
  border-radius: $global-radius;
	.messenger-message-slot.messenger-shown {
		border: 1px solid #666;
    margin-bottom: 10px;
    background: #f5f5f5;
    border-radius: 3px;
		.messenger-message {
			color: $pdc-body-copy;
			p {
				margin-bottom: 0;
			}
		}
		.messenger-message, &.messenger-first .messenger-message, &.messenger-last .messenger-message, &.messenger-first.messenger-last .messenger-message {
	    -webkit-border-radius: 0;
	    -moz-border-radius: 0;
	    -ms-border-radius: 0;
	    -o-border-radius: 0;
	    border-radius: 0;
	    -webkit-box-shadow: inset 48px 0px 0px $pdc-body-copy;
	    -moz-box-shadow: inset 48px 0px 0px $pdc-body-copy;
	    box-shadow: inset 48px 0px 0px $pdc-body-copy;
	  }
	}
}
ul.messenger.messenger-fixed.messenger-on-top {
  top: 16px;
}
