/* Styling for the Portal Config */
.portalCompanyName.float-left {
  padding:0.5rem;
  .stored-company {
    font-size:1.2rem;
    margin:0;
    padding:0.7rem 1rem;
  }
}

header#mobile-nav {
  .stored-company-mobile.portalCompanyName.float-left {
    font-size:1.1rem;
    padding:0;
  }
}

// Imitate Responsive Styling
#sizer.smallForm #pdcForm .cell, #sizer.smallForm #pdcForm #paymentRow div {
  width:100%;
  &#cardExpiration .cell {
    width:30%;
  }
  &.medium-offset-3 {
    margin-left:0;
  }
  label.fakeLabelMedium {
    line-height:0;
  }
}

// current code never allows Large to exist, so code is commented for now
// Card Form large
/*#sizer.largeForm #pdcForm #cardForm .paymentInfo #paymentRow {
  flex-direction:row;
}
#sizer.largeForm #pdcForm #cardInfo, #sizer.largeForm #pdcForm #cardExtraInfo {
  display:flex;
}
#sizer.largeForm #pdcForm #cardInfo > div, #sizer.largeForm #pdcForm #cardExtraInfo > div {
  overflow:hidden;
  text-overflow:ellipsis;
  width:100%;
}*/
// Check Form large
/*#sizer.largeForm #pdcForm #checkForm .paymentInfo #paymentRow {
  flex-direction:row;
}
#sizer.largeForm #pdcForm .formElement#div-check-paymentAmount, #sizer.largeForm #pdcForm .formElement#div-check-totalAmount {
  width:33.33333%;
}
#sizer.largeForm #pdcForm .formElement#div-check-feeAmount {
  width:66.66666%;
}
	#sizer.largeForm #pdcForm #checkBankInfo {
		display:flexbox;
		display:flex;
	}
	#sizer.largeForm #pdcForm #checkBankInfo > div {
		overflow:hidden;
		text-overflow:ellipsis;
		width:100%;
	}
	#sizer.largeForm #pdcForm .formElement#checkBankAccount, #sizer.largeForm #pdcForm .formElement#checkBankAccountConfirm, #sizer.largeForm #pdcForm .formElement#checkBankRouting, #sizer.largeForm #pdcForm .formElement#checkNumber {
		width:100%;
	}
	#sizer.largeForm #pdcForm .formElement#checkMemo, #sizer.largeForm #pdcForm .formElement#div-check-memo {
		width:100%;
	}
	#sizer.largeForm #pdcForm .formElement#checkStateCountry, #sizer.largeForm #pdcForm .formElement#checkZips {
		display:flexbox;
		display:flex;
		width:50%;
	}
	#sizer.largeForm #pdcForm .formElement#checkStateCountry > div, #sizer.largeForm #pdcForm .formElement#checkZips > div {
		overflow:hidden;
		text-overflow:ellipsis;
		width:100%;
	}
	#sizer.largeForm #pdcForm .formElement#checkStateCountry .formElement, #sizer.largeForm #pdcForm .formElement#checkZips .formElement {
		width:100%;
	}
	#sizer.largeForm #pdcForm .formElement#checkSubmit {
		clear:both;
	}*/
/* End of Portal Config Styles  */
/* Start Preview Form Styles - styles after line break could be overwritten in custom Styles */
#pdcFormContainer {
  float:left;
  width:100%;
  #schedulePreviewDiv {
    #paymentPreviewSummary {
      background-color:$pdc-grey;
      padding:5px;
      width:100%;
    }
  }
}

#pdcForm {
  box-sizing:border-box;
  color:#666666;
  float:left;
  margin-top:0.9375rem;
  padding:10px;
  width:100%;
  font-family:'Lato', 'Helvetica Neue', Helvetica, 'Roboto', Arial, sans-serif;
  font-weight:normal;
  line-height:1.8;
  #cardAddress, #cardContact {
    clear:both;
  }
  .hide, .hideToo {
    display:none !important;
  }
  .field-error {
    margin-bottom:0;
    margin-top:0;
    position:relative;
    z-index:0;
  }
  iframe {
    position:relative;
    z-index:1;
  }
  label {
    font-family:inherit;
    font-weight:inherit;
    line-height:inherit;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    color:#666666;
    display:block;
    font-size:12px;
    margin:0;
    text-transform:none;
    @media (max-width:700px) {
      &.fakeLabelMedium {
        line-height:0;
      }
    }
  }
  & input, & input[type='text'], & input[type='password'], & input[type='date'], & input[type='datetime'], & input[type='datetime-local'], & input[type='month'], & input[type='week'], & input[type='email'], & input[type='number'], & input[type='search'], & input[type='tel'], & input[type='time'], & input[type='url'], & input[type='color'], & textarea {
    margin:0;
    position:relative;
    z-index:1;
  }
  & input.customStyled, & input[type=text].customStyled, & input[type=password].customStyled, & input[type=date].customStyled, & input[type=datetime].customStyled, & input[type=datetime-local].customStyled, & input[type=month].customStyled, & input[type=week].customStyled, & input[type=email].customStyled, & input[type=number].customStyled, & input[type=search].customStyled, & input[type=tel].customStyled, & input[type=time].customStyled, & input[type=url].customStyled, & input[type=color].customStyled, & textarea.customStyled {
    -moz-appearance:none;
    -webkit-appearance:none;
    box-sizing:border-box;
    display:block;
    font-family:inherit;
    margin:0;
    transition:box-shadow .5s, border-color .25s ease-in-out;
    width:100%;

    background-color:#fefefe;
    border-color:#cccccc;
    border-radius:3px;
    border-style:solid;
    border-width:1px;
    box-shadow:$input-shadow;
    color:#666666;
    font-size:16px;
    height:40px;
    padding:10px;
    text-transform:none;
  }
  & input:focus.customStyled, & input[type=text]:hover.customStyled, & input[type=email]:hover.customStyled, & input[type=password]:hover.customStyled, & input[type=tel]:hover.customStyled, & input[type=number]:hover.customStyled, & input[type=url]:hover.customStyled, & select:hover.customStyled, & textarea:hover.customStyled, & input:focus.customStyled, & input[type=text]:focus.customStyled, & input[type=password]:focus.customStyled, & input[type=date]:focus.customStyled, & input[type=datetime]:focus.customStyled, & input[type=datetime-local]:focus.customStyled, & input[type=month]:focus.customStyled, & input[type=week]:focus.customStyled, & input[type=email]:focus.customStyled, & input[type=number]:focus.customStyled, & input[type=search]:focus.customStyled, & input[type=tel]:focus.customStyled, & input[type=time]:focus.customStyled, & input[type=url]:focus.customStyled, & input[type=color]:focus.customStyled, & textarea:focus.customStyled {
    outline:0;

    background-color:#ffffff;
    border-color:#0000ee;
    box-shadow:$input-shadow-focus;
  }
  input[readonly].customStyled {
    background-color:#e8e8e8;
    border-color:#cccccc;
    &:active, &:focus, &:hover {
      background-color:#e8e8e8;
      border-color:#cccccc;
      box-shadow:$input-shadow-focus;
    }
  }
  select {
    -moz-appearance:none;
    -webkit-appearance:none;
    background:none;
    box-sizing:border-box;
    cursor:pointer;
    font-family:inherit;
    line-height:1;
    margin:0;
    position:relative;
    transition:box-shadow .5s, border-color .25s ease-in-out;
    width:100%;
    z-index:1;

    background-color:#fefefe;
    border-color:#cccccc;
    border-radius:3px;
    border-style:solid;
    border-width:1px;
    box-shadow:$input-shadow;
    color:#666666;
    font-size:16px;
    height:40px;
    padding:0;
    padding-left:10px;
    padding-right:25px;
    &:hover {
      outline:0;

      background-color:lighten($pdc-base-color, 62%);
      border-color:$pdc-hover-color;
      box-shadow:$input-shadow-focus;
    }
    &:disabled {
      background-color:$pdc-background-color;
      border-color:$pdc-grey;
      &:hover {
        background-color:$pdc-background-color;
        border-color:$pdc-body-copy;
      }
    }
  }
  .selectArrow {
    position:relative;
    &::after {
      color:#666666;
      content:"▼";
      cursor:pointer;
      font-size:0.6rem;
      margin-top:-7px;
      pointer-events:none;
      position:absolute;
      right:10px;
      top:50%;
    }
  }
  button {
    -moz-appearance:none;
    -webkit-appearance:none;
    border-radius:3px;
    border-style:solid;
    border-width:1px;
    border:none;
    cursor:pointer;
    display:inline-block;
    font-family:inherit;
    line-height:1;
    margin:0;
    transition:background-color .25s ease-out, color .25s ease-out;
    width:100%;
    &:hover {
      border:none;
      outline:0;
    }
    small {
      float:left;
      margin-top:0.1rem;
      padding-right:6px;
      &.hide, &.hideToo {
        display:none !important;
      }
    }
  }
  p {
    &.title {
      line-height:inherit;
      margin:0;

      font-size:16px;
      padding-left:10px;
      padding-right:10px;
    }
    &.pdc-required {
      line-height:inherit;
      margin:0;

      font-size:0.75rem;
      margin-top:10px;
      padding-left:0;
      padding-right:0;
    }
  }
  .field-success {
    display:block;
    margin-top:0;
    margin-bottom:0;
    padding:10px 15px;
    word-break:break-word;
    -webkit-hyphens:auto;
    -moz-hyphens:auto;
    -ms-hyphens:auto;
    hyphens:auto;
  }
  .paymentContainer {
    box-sizing:border-box;
    float:left;
    width:100%;
  }
  #paymentRow {
    box-sizing:border-box;
    float:left;
    width:100%;
    #scheduleBlock {
      width:100%;
      #paymentTypeTogglediv {
        margin:0;
        width:fit-content;
      }
      .radio-group {
        label {
          align-items:center;
          display:flex;
          float:none;
          overflow:visible;
          width:auto;
        }
        input[type=radio] {
          height:15px;
          left:0;
          margin:0 5px;
          overflow:visible;
          width:15px;
        }
        span {
          background:transparent;
          border:none;
          color:inherit !important;
          display:inline;
          padding:0;
          width:auto;
          &:before {
            display:none;
          }
        }
      }
    }
    hr {
      border-bottom-color:#cccccc;
      border-bottom-style:solid;
      border-bottom-width:1px;
      margin-bottom:10px;
      margin-left:10px;
      margin-right:10px;
      margin-top:10px;
    }
  }
  .confirmationContainer {
    box-sizing:border-box;
    float:left;
    width:100%;
    hr {
      border-bottom-color:#cccccc;
      border-bottom-style:solid;
      border-bottom-width:1px;
      margin-bottom:10px;
      margin-left:10px;
      margin-right:10px;
      margin-top:10px;
    }
  }
  #cardInfo {
    width:100%;
    #cardNumber, #cardNumberContainer, #div-card-cardNumber {
      min-width:36%;
    }
    #cardExpiration {
      min-width:40%;
    }
  }
  #cardExtraInfo {
    padding:0;
    width:100%;
  }
  #checkBankInfo {
    width:100%;
    #div-check-bankAccountPrepopulated {
      display:none;
    }
  }
  & #cardAddress::after, & #cardAddress::before, & #checkAddress::after, & #checkAddress::before, & #cardContact::after, & #cardContact::before, & #checkContact::after, & #checkContact::before {
    clear:both;
    content:'';
    display:block;
  }
  & #cardForm, & #checkForm, & #paymentMethodToggle {
    display:none;
    float:left;
    width:100%;
    /* payment method selection buttons */
    .paymentInfo {
      margin-bottom:15px;
      & button {
        text-align:left;
        & span {
          float:right;
        }
        & i {
          font-size:1.3em;
          margin-right:5px;
        }
      }
    }
    .grid-x .cell:empty {
      display:none;
    }
  }
  &.creditCard #cardForm {
    display:block;
    #dialogIdOutput {
      margin-top:20px;
    }
  }
  &.check #checkForm {
    display:block;
  }
  &.combined .paymentInfo #div-card-paymentType, &.combined .paymentInfo #div-check-paymentType {
    display:block;
  }
  & input:-ms-input-placeholder, & textarea:-ms-input-placeholder {
    color:#cccccc;
    opacity:1;
  }
  & input:hover:-ms-input-placeholder, & textarea:hover:-ms-input-placeholder, & input:focus:-ms-input-placeholder, & textarea:focus:-ms-input-placeholder {
    color:#cccccc;
    opacity:1;
  }
  & input::placeholder {
    color:#cccccc;
    opacity:1;
  }
  & input:focus::placeholder, & input:hover::placeholder {
    color:#cccccc;
    opacity:1;
  }
  #error-handler-container {
    .error-404 {
      border-radius:$global-radius $global-radius 0 0;
      margin-bottom:0;
      margin-top:10px;
    }
    .error-content {
      border:1px solid $alert-color;
      border-radius:0 0 $global-radius $global-radius;
      box-sizing:border-box;
      padding:1rem;
    }
    .button {
      margin-bottom:0;
      width:100%;
    }
  }
  div.input-field .select2-container {
    margin-bottom:0;
  }
  #paymentRow {
    display:flex;
    margin-bottom:20px;
    .paymentText {
      align-items:center;
      border-color:#cccccc;
      border-radius:3px;
      border-style:solid;
      border-width:1px;
      display:flex;
      float:left;
      height:100%;
      padding:5px 10px;
      width:100%;
      label {
        display:inline;
        font-size:inherit;
        font-style:italic;
        overflow:auto;
        text-overflow:inherit;
        white-space:inherit;
      }
    }
  }
}

@media (min-width:700px) {
  /* Medium screen styling */
  #pdcFormContainer #pdcForm.active, #pdcFormContainer #transactionResult {
    max-width:800px;
    .portalReceiptContainer {
      margin:0 10px;
    }
    @media (min-width:800px) {
      margin-left:calc(50% - 400px);
    }
    #cardInfo, #cardExtraInfo {
      display:flex;
      & > div {
        width:100%;
      }
    }
    #checkBankInfo {
      display:flex;
      width:100%;
      & > div {
        overflow:hidden;
        text-overflow:ellipsis;
        width:100%;
      }
    }
    .paymentInfo {
      #paymentRow {
        flex-direction:row;
      }
    }
  }
}

/* End Preview Form Styles */
/* Start Potal Receipt Styling */
.portalReceiptContainer {
  .button {
    margin:0;
  }
  [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    margin-bottom:0;
  }
  .emailForm {
    a.button {
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }
    #receiptEmailSingle, #receiptEmailSchedule {
      display:flex;
      & > div:first-child {
        flex-shrink:1;
        min-width:60%;
        width:100%;
      }
      & > div:last-child {
        flex-shrink:2;
        min-width:40%;
        width:100%;
      }
      button {
        border-bottom-left-radius:0;
        border-top-left-radius:0;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
      }
      .field-error {
        margin-bottom:0;
        padding-top:25px;
        position:relative;
        z-index:0;
      }
      input {
        border-bottom-right-radius:0;
        border-top-right-radius:0;
        position:relative;
        z-index:2;
      }
    }
  }
  #additionalButtons {
    margin-top:1rem;
    a.button {
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }
  }
  #portalReceipt, #portalReceiptSchedule {
    margin-bottom:2rem;
    margin-top:1rem;
    section {
      .pushDown.formatting {
        background:$white;
        border:1px solid $pdc-grey;
        max-width:100% !important;
        table {
          tbody {
            border:none;
          }
        }
        @media screen {
          table {
            border:none !important;
            margin-bottom:1rem !important;
            td {
              padding:0.5rem 1rem !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width:768px) {
  .portalReceiptContainer {
    .emailForm {
      #receiptEmailSingle, #receiptEmailSchedule {
        margin-bottom:1rem;
      }
    }
    #error-handler-container {
      .row.collapse {
        /*margin-bottom:1rem;*/
      }
    }
    #additionalButtons {
      a.button {
        margin-bottom:1rem;
      }
    }
  }
}

/* End Portal Receipt Styling */
/* Trustwave Logog for Portal in iFrame */
#trustwave-logo {
  display:none;
  @include breakpoint(small only) {
    margin-bottom:1rem;
  }
}

/* End */
/* Styling required to make IE11 behave */
body.explorer, body.edge {
  #editor {
    form {
      height:100%;
      overflow-y:hidden;
      width:100%;
    }
    #tabsLists {
      height:100%;
      overflow-y:hidden;
      position:absolute;
      top:0;
      width:100%;
      .tabs-panel {
        height:100%;
        overflow-y:scroll;
        padding-top:120px;
        position:absolute;
        top:0;
        width:100%;
        & > div:last-of-type {
          margin-bottom:60px;
        }
      }
    }
  }
  #pdcForm {
    width:100%;
  }
}

#div-recaptcha-response {
  width:100% !important;
}
