.view-header { margin-bottom: 10px; }

.input-field {
	.secureOverlay {
    border:none;
		float: left;
		height:40px;
		width: 100%;
	  iframe {
	    height: 40px;
	    width: 100%;
	    body {
	      font-family: $body-font-family !important;
	      font-weight: normal !important;
	    }
	  }
	}
	label {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
  span.counter {
		padding-bottom:1.4rem;
		z-index:2;
	}
  &#securityAnswerdiv {
    label {
      overflow:auto;
      padding-left:6px;
      position:relative;
      text-overflow:initial;
      white-space:normal;
      &::before {
        left:0;
        position:absolute;
        top:0;
      }
    }
  }
  .emptyElement {
    height:30px;
  }
}

ul.pdc-horizontal.tabs {
  background: none;
  border: none;
  li {
    a {
      background-color: $white-smoke;
      @include border-1-solid-grey;
      border-left: none;
      font-size: 1.000rem;
      padding: 12px 20px;
      text-transform: uppercase;
      &:hover {
        background-color: $pdc-hover-color;
        color: $white;
      }
      @include breakpoint (small only){
        @include border-1-solid-grey;
        border-left: 1px solid $pdc-grey;
        border-bottom: none;
      }
    }
    &:first-child {
      a {
        border-left: 1px solid $pdc-grey;
        border-top-left-radius: $global-radius;
        border-bottom-left-radius: $global-radius;
        @include breakpoint (small only){
          border-top-right-radius: $global-radius;
          border-bottom-left-radius: 0;
        }
      }
    }
    &:last-child {
      a {
        border-top-right-radius: $global-radius;
        border-bottom-right-radius: $global-radius;
        @include breakpoint (small only){
          border-top-right-radius: 0;
          border-bottom-left-radius: $global-radius;
          border-bottom: 1px solid $pdc-grey;
        }
      }
    }
    &.is-active {
      a {
        background-color: $pdc-base-color;
        color: $white;
        cursor: default;
      }
    }
  }
	&.flexNav {
		align-items: stretch;
		display: flex;
	  li {
			display:inline;
			float:left;
			overflow: hidden;
			position: relative;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
	    a {
				display:inline;
				float:left;
				overflow: hidden;
				text-overflow: ellipsis;
				position: relative;
				white-space: nowrap;
				width:100%;
			}
		}
	}
  @include breakpoint(small only){
		&.flexNav {
			display: block;
		}
    @include tabs-container-vertical;
  }
}

.tabs-content {
  background: none;
  border: none;
  .tabs-panel {
    padding: 10px 15px 5px 15px;
    p {
      font-size: 0.850rem;
      margin-top: 10px;
    }
  }
  ul.tabs {
    li {
      display: inline;
      margin-right: 20px;
      a {
        background: none;
        border: none;
        font-size: 0.750rem;
        padding: 0;
        text-transform: capitalize;
        &:hover {
					color: $pdc-hover-color;
					background: transparent;
				}
        &.sub-is-active {
          &::before {
            content: "\f054\00a0";
            font-family: $font-awesome-family;
          }
        }
      }
    }
  }
}

#pdc-flow-template-config .tabs-content .tabs-panel { padding: 0; }

.pdc-form-config-header { margin-bottom: 20px; }

.pdc-form-options {
  div.input-field { margin-bottom: 20px; }
}

.panel-hidden { display: none; }

a.pdc-recent {
  color: $pdc-lighter-copy;
  transition: 0s;
  h6 {
    color: $pdc-lighter-copy;
    border-top-color: $pdc-grey;
    border-top-style: solid;
    border-width: 1px;
    font-size: 0.850rem;
    margin-bottom: 0;
    padding-top: 10px;
  }
  p {
    font-size: 0.750rem;
    margin-bottom: 0;
    small { font-size: 0.600rem; }
    &:last-child { margin-bottom: 10px; }
  }
  &:hover {
    color: $pdc-body-copy;
    h6 { color: $pdc-body-copy; }
  }
}

.recent-results {
  max-height: 500px;
  overflow: auto;
}

.secureEntry {
  position: relative;
}
.secureEntrySide {
  position:absolute;
  right:10px;
  top:28px;
  z-index:1;
}
#scheduleForm .secureEntrySide {
  margin-right: 10px;
  top: 10px;
}
.secureEntry.field-error {
	float: left;
	margin-top: -1px;
	width:100%;
}

.margin-top-adjust {
  margin-top: 0 !important;
}

#formForOverlay, #overlayPreviewDiv {
  height: 100%;
  overflow: hidden;
  margin: 0;
  position: relative;
  article {
    height: 100%;
    float: right;
    overflow: auto;
		padding-bottom: 20px;
    padding-top: 20px;
  }
  aside {
    height: 100%;
    float: left;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: auto;
    form {
			padding-right: 10px;
		}
    .button {
      margin-bottom: 0;
      width: 100%;
    }
    .radio-group {
			background:#ffffff;
			border-radius:3px;
      margin-bottom: 1rem;
      .rb-02, .rb-03, .rb-04, .rb-05 {
        width: 100%;
      }
    }
    .radio-group label span {
      border-left-width: 1px;
    }
    .radio-group label.label-left input:checked + span, .radio-group label.label-left span {
      border-top-right-radius: $global-radius;
      border-bottom-left-radius: 0;
      border-bottom-width: 0;
    }
    .radio-group label.label-center input:checked + span, .radio-group label.label-center span {
      border-bottom-width: 0;
    }
    .radio-group label.label-right input:checked + span, .radio-group label.label-right span {
      border-top-right-radius: 0;
      border-bottom-left-radius: $global-radius;
    }
    .input-field {
      position: relative;
    }
    .dropdown-pane {
      left: 0 !important;
      top: 20px !important;
      width: 100% !important;
    }
		#ajaxStatusMsg {
			margin:0 0 1rem 0;
		}
  }
	@include breakpoint(small only){
		overflow: auto;
		article {
			height: auto;
			.pdc-overlay {
				margin-top: 0;
				padding-left: 0;
				padding-right: 0;
			}
		}
		aside {
			height:auto;
			form {
				padding-right: 0;
			}
		}
	}
}


#overlayElementdiv {
	.radio-group {
		label {
			input:checked {
				&#overlayElement1 {
					+span {
						background-color:rgba(0,0,255,0.5);
						color:$black !important;
					}
				}
				&#overlayElement2 {
					+span {
						background-color:rgba(0,255,0,0.5);
						color:$black !important;
					}
				}
				&#overlayElement3 {
					+span {
						background-color:rgba(255, 183, 0, 0.5);
						color:$black !important;
					}
				}
				&#overlayElement4 {
					+span {
						background-color:rgba(0,0,255,0.5);
						color:$black !important;
					}
				}
				&#overlayElement5 {
					+span {
						background-color:rgba(255,0,0,0.5);
						color:$black !important;
					}
				}
			}
		}
	}
}

#pdfImage { position:relative; }

.pdc-overlay {
  width: 100%;
  height: auto;
  position: relative;
  img {
    width: 100%;
    float: right;
  }
}

.overlay-blue {
  background-color: rgba(0,0,255,0.5);
  background-size: 100%;
  cursor: move;
  position: absolute;
  display: block;
  z-index: 13;
	#overlayPreviewDiv & {
		cursor: default;
	}
}

.overlay-green {
  background-color: rgba(0,255,0,0.5);
  background-size: 100%;
  cursor: move;
  position: absolute;
  display: block;
  z-index: 13;
	#overlayPreviewDiv & {
		cursor: default;
	}
}

.overlay-red {
  background-color: rgba(255,0,0,0.5);
  background-size: 100%;
	color:$black;
  cursor: move;
  position: absolute;
  display: block;
  z-index: 13;
	#overlayPreviewDiv & {
		cursor: default;
	}
	.overlay-textarea-sample {
		height:100%;
		overflow:hidden;
    pointer-events:none;
		width:100%;
	}
	.overlay-textarea-font-size {
		background:rgba(0,0,0,0.5);
		margin:0;
		padding:10px;
		position:absolute;
		top:100%;
		width:100%;
		.select-after {
			select {
				font-size:14px;
				margin:0;
			}
		}
	}
}

.overlay-yellow {
  background-color: rgba(255, 183, 0, 0.5);
  background-size: 100%;
  cursor: move;
  position: absolute;
  display: block;
  z-index: 13;
	#overlayPreviewDiv & {
		cursor: default;
	}
}

.overlay-close {
  background-color: $black;
  background-size: 100%;
  color: $white;
  cursor: pointer;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 14;
  top: -10px;
  right: -10px;
  &::before {
    content: '\f00d';
    font-family: $font-awesome-family;
    font-size: 0.750rem;
    font-weight: 900;
    position: absolute;
    top: -2px;
    left: 3px;
  }
}

p.pdc-required {
	color: $pdc-body-copy;
	font-size: 0.750rem;
}

#minimumAmountRow {
	.radio-group {
		margin-bottom:1rem;
	}
}

.expandingArea {
  position:relative;
  z-index:1;
	> textarea {
    box-sizing: border-box;
	  width: 100%;
	  height: 120px;
	}
	> div {
    border:1px solid transparent;
		font-family:$body-font-family;
	  display: none;
		min-height:120px;
		padding:0.5rem 0.5rem 1.4rem 0.5rem;
		width:100%;
		span {
			display:inline-block;
			white-space:pre-wrap;
			width:100%;
		}
	}
	&.active {
		> textarea {
		  overflow: hidden;
		  position: absolute;
		  top: 0;
		  left: 0;
		  height: 100%;
		  resize: none;
		}
		> div {
		  display: block;
		  visibility: hidden;
		}
	}
}
// Select2 Style Overrides
div.input-field {
  .select2-container {
    margin:0 0 $global-margin;
    &.select2-container--disabled {
      .select2-selection--single {
        background-color: #e8e8e8;
        border-color: #d0d0d0;
        &:focus, &:hover {
          background-color: #e8e8e8;
          border: 1px solid #666666;
          box-shadow: none;
        }
      }
    }
    .select2-selection--single {
      appearance:none;
      background-color:$input-background;
      border:$input-border;
      border-radius:$input-radius;
      box-shadow:$input-shadow;
      box-sizing:border-box;
      color:$input-color;
      display:block;
      font-family:$input-font-family;
      font-size:$input-font-size;
      font-weight:$input-font-weight;
      height:40px;
      line-height:$input-line-height;
      padding:$input-padding;
      transition:$input-transition;
      width:100%;
      &:hover {
        background: lighten($pdc-base-color, 62%);
        border-color: $pdc-hover-color;
      }
      &:focus {
        border: 1px solid $pdc-body-copy;
        box-shadow: 0 0 5px #cacaca;
        outline: none;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      }
      &:disabled {
        background-color: $pdc-background-color;
        border-color: $pdc-grey;
        &:hover {
          background-color: $pdc-background-color;
          border-color: $pdc-body-copy;
        }
      }
      .select2-selection__rendered {
        padding-left:0;
      }
    }
    .select2-selection--multiple {
      appearance:none;
      background-color:$input-background;
      border:$input-border;
      border-radius:$input-radius;
      box-shadow:$input-shadow;
      box-sizing:border-box;
      color:$input-color;
      display:block;
      font-family:$input-font-family;
      font-size:$input-font-size;
      font-weight:$input-font-weight;
      line-height:$input-line-height;
      min-height:40px;
      padding:$input-padding;
      transition:$input-transition;
      width:100%;
      &:hover {
        background-color: lighten($pdc-base-color, 62%);
        border-color: $pdc-hover-color;
      }
      &:focus {
        border: 1px solid $pdc-body-copy;
        box-shadow: 0 0 5px #cacaca;
        outline: none;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      }
      &:disabled {
        background-color: $pdc-background-color;
        border-color: $pdc-grey;
        &:hover {
          background-color: $pdc-background-color;
          border-color: $pdc-body-copy;
        }
      }
    }
  }
  .select2-container--default {
    .select2-selection--single{
      .select2-selection__arrow {
        display:none;
      }
      .select2-selection__rendered {
        color:inherit;
        line-height:inherit;
      }
    }
    .select2-selection--multiple {
      .select2-selection__rendered {
        padding:0 20px 0 0;
        .select2-search--inline {
          margin-top:5px;
          padding:0.25rem 0.5rem;
          .select2-search__field {
            height:auto;
            line-height:inherit;
            margin:0;
            transition:none;
          }
        }
        .select2-selection__choice {
          background:$pdc-body-copy;
          border:none;
          color:$white;
          padding:0 0.5rem 0 0;
          .select2-selection__choice__remove {
            background:$pdc-base-color;
            border-bottom-left-radius:$input-radius;
            border-top-left-radius:$input-radius;
            color:$white;
            margin-right:0.5rem;
            padding:0.25rem 0.5rem;
          }
        }
        .select2-selection__clear {
          margin-right:0;
          padding:0.25rem 0;
        }
      }
    }
  }
}
.select2-container--open {
  .select2-dropdown {
    background-color:$input-background;
    border:$input-border;
    border-top:none;
    border-radius:$input-radius;
    border-top-left-radius:0;
    border-top-right-radius:0;
    &.select2-dropdown--above {
      border:$input-border;
      border-bottom:none;
      border-radius:$input-radius;
      border-bottom-left-radius:0;
      border-bottom-right-radius:0;
    }
    .select2-search--dropdown {
      padding:$input-padding;
      .select2-search__field {
        border:$input-border;
        padding:$input-padding;
        margin:0;
      }
    }
  }
  .select2-results {
    .select2-results__options {
      .select2-results__option {
        padding:$input-padding;
        &.select2-results__option--highlighted[aria-selected] {
          background:$pdc-base-color;
          color:$white;
        }
      }
    }
  }
}

#locationIdNameLabel {
  background:$input-background-disabled;
  border:$input-border;
  border-radius:$global-radius;
  box-shadow:$input-shadow;
  color:$pdc-body-copy;
  height:40px;
  padding:$input-padding 0;
  white-space:nowrap;
  i {
    color:$anchor-color;
    cursor:pointer;
    line-height:$input-line-height;
    &:active, &:focus, &:hover {
      color:$pdc-hover-color;
    }
  }
  span {
    overflow:hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
}

#currentPassworddiv, #passworddiv, #passwordConfirmdiv, #passwordCurrentdiv  {
  position:relative;
  input {
    padding-right:calc(16px + 1rem);
  }
  .toggleFieldType {
    line-height:16px;
    position:absolute;
    right:0.5rem;
    top:33px;
  }
}
.login-container {
  #passworddiv  {
    position:relative;
    input {
      padding-right:calc(16px + 1rem);
    }
    .toggleFieldType {
      line-height:16px;
      position:absolute;
      right:0.5rem;
      top:12px;
    }
  }
}

#duplicateWarning {
  padding:10px;
  #allowDuplicatediv {
    align-items:stretch;
    display:flex;
    flex-direction:row;
    .pdc-checkboxes {
      margin-bottom:0;
      .pdc-check-item {
        align-items: center;
        background:$white-smoke;
        border:2px solid $alert-color;
        border-right:none;
        display:flex;
        flex-grow: 1;
        height:100%;
        input {
          display:none;
        }
        input:checked + label {
          background:transparent;
          color:$black;
        }
        label {
          border:none;
          font-size:1rem;
          margin-right:0;
          text-transform:none;
          &:hover {
            background:transparent;
            color:$black;
          }
          &::before {
            display:inline-block;
            width:18px;
          }
        }
      }
    }
    .field-error {
      align-items:center;
      border-radius:0 3px 3px 0;
      display:flex;
      margin-bottom:0;
      &::before {
        float:left;
        font-size:1.6rem;
        padding-right:6px;
      }
    }
  }
}

.acceptCheckbox {
  padding:10px;
  .acceptCheckboxContainer {
    align-items:stretch;
    border:$input-border;
    border-radius:$global-radius;
    display:flex;
    padding:10px;
    .acceptCheckboxText {
      flex:1 1 auto;
      padding:10px;
    }
    .input-field {
      flex:0 0 auto;
      margin:0;
      width:auto;
      .pdc-checkboxes {
        height:100%;
        margin-bottom:0;
        .pdc-check-item {
          height:100%;
          input {
            &:checked {
              & + label {
                background:transparent;
                color:$pdc-base-color;
              }
            }
          }
          label {
            align-items:center;
            border:none;
            display:flex !important;
            font-size:1rem !important;
            height:100%;
            margin:0 !important;
            padding:10px;
            width:auto;
            &::before {
              display:block;
              height:14px;
              line-height:14px;
              padding-left:0;
              padding-right:0;
              width:14px;
            }
            &:active, &:focus, &:hover {
              background:transparent;
              color:$pdc-base-color;
            }
          }
        }
      }
    }
  }
}


.droppable {
  border: 1px solid $pdc-grey;
  list-style-type:none;
  margin:0;
  padding-bottom:2em;
  text-align:center;
  li {
    padding:5px;
    text-align:left;
    &:hover {
      background-color:$pdc-hover-color;
      color:$white;
      cursor:grab;
    }
  }
  &:after {
    content:"Drag and Drop Fields Here";
    display:block;
    margin-top:2em;
  }
}

.droppableFocus, .droppable:hover {
  background-color:lighten($pdc-base-color, 62%);
  border-color:$pdc-hover-color;
}

.droppablePlaceholder {
  height:2em;
  background-color:$pdc-base-color;
}

.fileUploadDrop {
  font-size:larger;
  padding:0 !important;
  .button {
    margin-bottom:0;
    padding:10px;
    width:100%;
  }
  label {
    padding:20px;
    cursor:pointer;
    p {
      margin:6px 0 !important;
      overflow:auto;
      .button {
        width:fit-content;
      }
    }
  }
  &.highlightDrop label.button {
    background-color:$pdc-hover-color;
    color:$white-smoke;
  }
}
.fileUploadReport {
  .fileUploadDrop, .fileRecord {
    margin-bottom:10px;
    &.medium-6.large-6 {
      @include breakpoint(medium up) {
        width:calc(50% - 10px);
      }
      .flex-nowrap {
        margin-right:10px;
      }
      &:nth-child(odd) {
        margin-right:20px;
      }
    }
  }
  .fileRecord {
    background-color:#f1f1f1;
    height:fit-content;
    .overlayRow {
      > div {
        margin: 0 4% 0 0;
        width: 48%;
        &:last-child {
          margin:0;
        }
      }
    }
    .dropdown-pane {
      font-size:smaller;
      max-width:145px;
    }
    .optionDropdown {
      float:right;
      margin-right:-10px;
      text-align:left;
    }
    .select2-container .select2-selection--single, select {
      line-height:1.1;
      max-height:35px;
    }
  }
  .fileNameOutput {
    white-space:nowrap;
    font-size:large;
    line-height:1.9;
    overflow:hidden;
    text-overflow:ellipsis;
  }
}
