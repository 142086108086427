.footer-container {
  background-color: $pdc-grey;
  bottom: 0;
  left: 0;
	padding:1.5rem 0;
	position: absolute;
  width: 100%;
}

footer {
  background-color: $pdc-grey;
	& > div {
		align-self:center;
	}
  .footer-logo {
    @include breakpoint(small only){
      text-align:center;
      margin-bottom: .5rem
    }
    img {
      width: auto;
      height: auto;
      max-width: 200px;
    }
  }
	.trustwave-logo {
		text-align:center;
		@include breakpoint(small only){
			margin-bottom:1rem;
			text-align:right;
		}
	}
	.site-info {
		@include breakpoint(small only){
			text-align:center;
		}
	}
  p {
    &.app-details {
      color: $pdc-body-copy;
      font-size: 0.600rem;
			margin-bottom:0;
    }
    &.pdc-support {
      text-transform: uppercase;
      font-size: 0.750rem;
			margin-bottom:0.5rem;
    }
  }
}
// Styling for IE & Edge
body.explorer, body.edge {
	.footer-container {
		box-sizing:content-box;
		footer {
			box-sizing:border-box;
		}
	}
}
