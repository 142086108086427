.ui-widget-overlay {
	background: #000;
	opacity: 0.7;
}
.ui-widget-content {
	background: $white;
	border-color: $pdc-grey;
}

.ui-widget-header {
	background: $white-smoke;
	border-color: $pdc-grey;
	color: $pdc-body-copy;
	a {
		color: $pdc-body-copy;
		&:hover .ui-icon, &:focus .ui-icon {
			color: $white;
		}
	}
	.ui-icon {
		background: transparent;
		color: $pdc-body-copy;
		&.ui-icon-circle-triangle-w {
			&::after {
				content: "\f104";
				font-family: $font-awesome-family;
				height:100%;
				left: 0;
				line-height: 16px;
				position: absolute;
				text-align: center;
				text-indent: 0;
				top: 0;
				width:100%;
			}
		}
		&.ui-icon-circle-triangle-e {
			&::after {
				content: "\f105";
				font-family: $font-awesome-family;
				height:100%;
				line-height: 16px;
				position: absolute;
				right:0;
				text-align: center;
				text-indent: 0;
				top: 0;
				width:100%;
			}
		}
		&.ui-icon-closethick {
			&::after {
				content: "\f00d";
		    font-family: $font-awesome-family;
		    height: 100%;
		    left: 0;
		    line-height: 16px;
		    position: absolute;
		    text-align: center;
		    text-indent: 0;
		    top: 0;
		    width: 100%;
			}
		}
	}
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
	border-color: $pdc-grey;
	background: $white-smoke;
	color: $pdc-body-copy;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
	border-color: $pdc-base-color;
	background: $pdc-hover-color;
	font-weight: bold;
	color: $white;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, .ui-state-active.ui-state-highlight, .ui-widget-content .ui-state-active.ui-state-highlight, .ui-widget-header .ui-state-active.ui-state-highlight {
	border-color: $pdc-base-color;
	background: $pdc-hover-color;
	font-weight: bold;
	color: $white;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
	//background: $pdc-background-color;
	border-color: $pdc-hover-color;
	color: $pdc-hover-color;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-state-active.ui-state-hover, .ui-widget-content .ui-state-active.ui-state-hover, .ui-widget-header .ui-state-active.ui-state-hover, .ui-state-active.ui-state-highlight.ui-state-hover, .ui-widget-content .ui-state-active.ui-state-highlight.ui-state-hover, .ui-widget-header .ui-state-active.ui-state-highlight.ui-state-hover {
	background: $pdc-hover-color;
	border-color: $pdc-base-color;
}

button.ui-button.ui-button-icon-only {
	border: none;
	background: transparent;
	.ui-icon {
		color: $pdc-base-color;
	}
	.ui-button-text {
		padding: 0;
	}
	&:hover, &:focus, &:active {
		background: transparent;
		.ui-icon {
			color: $pdc-hover-color;
		}
	}
}

.ui-state-default {
  font-size: 0.750rem;
}

.ui-tooltip {
  font-size: 0.750rem;
  padding: 4px 8px;
}

.ui-autocomplete {
  max-height: 200px;
  max-width: 310px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden;
  @include breakpoint (small only){
    max-width: 768px;
    width: 93%;
  }
}

.ui-button .ui-button-text, .ui-button.ui-state-focus {
  &:hover, &:focus, &:active { font-weight: bold; }
}

.ui-dialog {
	padding: 0;
	.ui-dialog-titlebar {
		border: none;
		border-bottom: 1px solid $pdc-grey;
		border-radius: 0;
		padding: 0.4rem 1.5rem 0.4rem 1rem;
		.ui-dialog-titlebar-close {
			right: 0.4rem;
		}
	}
	.ui-dialog-content {
		padding: 1rem;
	}
	.ui-dialog-buttonpane {
		margin: 0;
		padding: 0;
	}
}
