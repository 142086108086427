body#iframed {
	background:transparent;
	& header, & .footer-container {
		display:none;
	}
	main.columns {
		margin-bottom:0;
	}
	#trustwave-logo {
		display:block;
	}
}
