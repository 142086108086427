#totalCompany .cell, #totalCustomer .cell {
  //border:thin solid $dark-gray;
  padding:20px 10px;
  //background-color:$white;
  border: thin solid #B09DE9;
  background-color:#e7e1f2;
}

#totalCompany.grid-x {
  width:auto !important;
}
#totalCompany .cell.feature, #totalCustomer .cell.feature {
  border:thin solid lighten($pdc-alert-color, 5%);
  background-color:lighten($pdc-alert-color, 35%);
  font-weight:bold;
}

.tabs-panel {
  margin-top:20px;
  padding:0;
}
.tabs-content .tabs-panel {
  padding:0;
}

#customerTab {
  border:none;
  border-bottom:thin solid $medium-gray;
  .tabs-title>a {
    font-size:1rem;
    padding:1rem 1.5rem;
  }
  &.tabs-title>a:focus, .tabs-title>a[aria-selected=true] {
    background:#fff;
    color:$body-font-color;
    cursor:default;
    background-image: linear-gradient(to bottom, #fff 88%, $pdc-base-color 100%);
    /*background:
        linear-gradient(
                to left,
                $pdc-base-color 0%,
                $pdc-alert-color 50%,
                $pdc-base-color 100%

        )
        left
        bottom
        #fff
        no-repeat;
    background-size:100% 5px ;*/
  }
  &.tabs a:hover:not([aria-selected=true]) {
    background-image: linear-gradient(to bottom, #fff 88%, $pdc-hover-color 100%);
  }
}

#paymentMethodSelection {
  background-color:$pdc-background-color;
  border:thin solid $medium-gray;
  padding:10px 0;
  height:fit-content;
  select.changeBillingAccount {
    margin:0;
  }
}

#paymentMethodContainer {
  .paymentMethodBlock {
    border: thin solid $medium-gray;
    margin-bottom:20px;
    padding:10px 0;
  }
  .button {
    width:100%;
  }
}

.customerTag {
  background-color:#d8b0e9;
  color:#000;
  font-size:0.75rem;
  height:fit-content;
  margin:5px 0;
  padding:3px 7px;
  width:fit-content;
}

div.formBlocker {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100;
  cursor:not-allowed;
}

#fuse-importer-root {
  height:95% !important;
  margin: 0 2.5% !important;
  top: 2.5% !important;
  width:95% !important;
}

#customerList {
  #offCanvasRight1 {
    background-color:#fff;
    margin-top:76px;
    width:90%;
    button {
      left:1rem;
      right:auto;
    }
    #ajaxLoadDiv {
      margin-top:50px;
      padding:10px;
    }
  }
}
