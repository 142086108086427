.dataTables_wrapper {
  .button-group {
    margin-bottom: 5px;
    .button {
      border-radius: 0;
      border-width: 0;
      @include border-1-solid-grey;
      background-color: $white-smoke;
      color: $pdc-base-color;
			border-left: none;
			margin-right: 0 !important;
      &:first-child {
        border-top-left-radius: $global-radius;
        border-bottom-left-radius: $global-radius;
        @include border-1-solid-grey;
      }
      &:last-child {
        border-top-right-radius: $global-radius;
        border-bottom-right-radius: $global-radius;
      }
      &.fakeButton {
        cursor:default;
        opacity:0;
      }
      &.dropdown {
				position: relative;
				&::after {
	        color: $pdc-base-color;
	        content: "\f078" !important;
	        font-family: $font-awesome-family;
	        font-size: 0.750rem;
          font-weight: 900;
	        border: 0;
	        border-color: none;
	        border-top-style: solid;
	        border-bottom-width: 0;
	        position: absolute;
	        top: 50%;
					right: 14px;
	        float: right;
	        margin-left: 0;
					margin-top: -6px;
					width: 12px;
					height: 12px;
				}
      }
      &:hover, &:focus {
        background-color: $pdc-hover-color;
        color: $white;
        &.dropdown::after { color: $white; }
      }
      &:active {
        background-color: $pdc-base-color;
        color: $white;
        &.dropdown::after { color: $white; }
      }
    }
		&.dropdown-pane {
			.button {
				border-color:transparent;
				width:100%;
			}
		}
  }
  .dataTables_scroll {
    .dataTables_scrollBody {
      &.dataTablesFake {
        max-height: 50vh;
        overflow: auto;
        position: relative;
        width: 100%;
      }
      table {
        tr {
          td {
            .dropdown-pane.dropdown-pane-email {
              background:$pdc-body-copy;
              border:1px solid $pdc-grey;
              max-width:96%;
              padding:0.625rem;
              width:auto;
              form {
                display:-webkit-box;
                display:-ms-flexbox;
                display:flex;
                -ms-flex-flow:row wrap;
                flex-flow:row wrap;
                & > div {
                  margin:0;
                  -webkit-box-flex:1;
                  -ms-flex:1 1 0px;
                  flex:1 1 0px;
                  &#emailAddressEmaildiv, &#emailAddressEmailScheduleListdiv, &#emailAddressEmailPaymentListdiv {
                    display:block;
                    margin-right:0.9375rem;
                    #emailAddressEmailMsg {
                      display:none;
                      &.field-error {
                        border-radius:0 0 $global-radius $global-radius;
                        display:block;
                        line-height:18px;
                        margin:0;
                      }
                    }
                  }
                }
                input[type='email'], input[type='text'] {
                  margin:0;
                  width:250px;
                  &:active, &:focus {
                    box-shadow:none;
                  }
                }
                button.button {
                  box-sizing:border-box;
                  flex:0 0 140px;
                  margin:0;
                  max-height:40px;
                  width:140px;
                  &:focus, &:active {
                    border:none;
                    outline:0;
                  }
                }
                .field-error {
                  box-sizing:border-box;
                  margin:0;
                  width:250px;
                }
              }
              @include breakpoint (small only){
                width:96%;
                form {
                  display:block;
                  width:100%;
                  & > div {
                    &#emailAddressEmaildiv, &#emailAddressEmailScheduleListdiv, #emailAddressEmailPaymentListdiv {
                      margin:0 0 1rem 0;
                    }
                  }
                  input[type='email'], input[type='text'] {
                    width:100%;
                  }
                  button.button {
                    display:block;
                    width:100%;
                  }
                }
              }
            }
            .tableIcon {
              display:inline-block;
              margin-right:10px;
            }
            .button {
              background-color:$white;
              border:thin solid $pdc-base-color;
              color:$pdc-base-color;
              &:hover {
                background-color:$pdc-hover-color;
                border:thin solid $pdc-base-color;
                color:$white;
              }
              &.small {
                margin-bottom:0;
                margin-right:-1px;
                box-sizing:border-box;
              }
              &::after {
                content: none;
              }
            }
            .button.dropdown {
              padding:0;
            }
          }
          .actionColumn {
            width:5%;
          }
        }
      }
    }
  }
}

table.dataTable thead .sorting{
  background-image: none;
}
table.dataTable thead .sorting_asc{
  background-image: none;
}
table.dataTable thead .sorting_desc{
  background-image: none;
}
table.dataTable thead .sorting_asc_disabled{
  background-image: none;
}
table.dataTable thead .sorting_desc_disabled{
  background-image: none;
}

table.dataTable>tbody>tr.even>* {
  box-shadow:none;
}

table.dataTable tbody tr:nth-child(even) {
  background-color: #f6f6f6;
}

.dt-button-info {
  z-index: 1000 !important;
}

.dt-buttons {
  .dropdown {
    padding-right: 34px;
  }
}

div.dt-button-collection{
  z-index:100;
  .dropdown-pane.is-open.button-group.stacked {
    background-color: $white-smoke;
    border-radius: 0;
    padding: 0;
    margin: 0;
    width: 200px;
    .button {
      background-color: $white;
      border-radius: 0;
      color: $pdc-base-color;
      padding: 10px 15px;
      margin: 0;
      text-align: left;
      &:hover {
        background-color: $pdc-hover-color;
        color: $white;
      }
    }
  }
}

/* Fixed column styling */
table.dataTable {
  thead, tbody {
    border: none !important;
  }
  thead tr>.dtfc-fixed-left, thead tr>.dtfc-fixed-right {
    filter: brightness(96%);
  }
  tbody tr.odd>.dtfc-fixed-left:not(.dataTables_empty), tbody tr.odd>.dtfc-fixed-right:not(.dataTables_empty) {
    filter: brightness(96%);
  }
  tbody tr.even>.dtfc-fixed-left:not(.dataTables_empty), tbody tr.even>.dtfc-fixed-right:not(.dataTables_empty) {
    filter: brightness(92%);
  }
  tbody tr:hover {
    background-color: #f1f1f1;
    .dtfc-fixed-left, .dtfc-fixed-right {
      background-color: #f1f1f1;
    }
  }
}

div.dataTables_filter {
  label {
    input {
      border: 1px solid $pdc-grey !important;
      box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1) !important;
      color: $pdc-body-copy !important;
      &:hover {
        background-color: $white-smoke !important;
        border-color: $pdc-hover-color !important;
      }
      &:focus, &:active {
        background-color: $white !important;
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1) !important;
        border: 1px solid $pdc-body-copy !important;
      }
    }
  }
}

.table-btn-text {
  @include breakpoint (small only){
    display: none;
  }
}

// Data Tables + Foundation + Font Awesome 6 free
table.dataTable thead th {
	position: relative;
	background-image: none !important; /* Remove the DataTables bootstrap integration styling */
  white-space: nowrap;
	@include breakpoint(medium down) {
		font-size:0.8rem;
	}
}
table.dataTable td {
	text-align:middle;
}

table.dataTable thead>tr>th.sorting:before, table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>th.sorting_asc:before, table.dataTable thead>tr>th.sorting_asc:after,
table.dataTable thead>tr>th.sorting_desc:before, table.dataTable thead>tr>th.sorting_desc:after {
  font-family: $font-awesome-family;
  margin-top:-5px;
}
table.dataTable thead>tr>th.sorting:after {
  color: #ccc;
  opacity:1;
  content: "\f0dc";
}
table.dataTable thead>tr>th.sorting_asc:after {
  content: "\f0de";
}
table.dataTable thead>tr>th.sorting_desc:after {
  content: "\f0dd";
}
table.dataTable thead>tr>th.sorting:before, table.dataTable thead>tr>th.sorting_asc:before, table.dataTable thead>tr>th.sorting_desc:before {
  content: "";
}


/*
 * DataTables style pagination controls
 */
div.dataTables_paginate a.paginate_button.first,
div.dataTables_paginate a.paginate_button.previous {
	position: relative;
	padding-left: 24px;
}

div.dataTables_paginate a.paginate_button.next,
div.dataTables_paginate a.paginate_button.last {
	position: relative;
	padding-right: 24px;
}

div.dataTables_paginate a.first:before,
div.dataTables_paginate a.previous:before {
	position: absolute;
	top: 8px;
	left: 10px;
	display: block;
  font-family: $font-awesome-family;
}

div.dataTables_paginate a.next:after,
div.dataTables_paginate a.last:after {
	position: absolute;
	top: 8px;
	right: 10px;
	display: block;
  font-family: $font-awesome-family;
}

div.dataTables_paginate a.first:before {
	content: "\f100";
}

div.dataTables_paginate a.previous:before {
	content: "\f104";
}

div.dataTables_paginate a.next:after {
	content: "\f105";
}

div.dataTables_paginate a.last:after {
	content: "\f101";
}

/*
 * Bootstrap and foundation style pagination controls
 */
div.dataTables_paginate li.first > a,
div.dataTables_paginate li.previous > a {
	position: relative;
	padding-left: 24px;
}

div.dataTables_paginate li.next > a,
div.dataTables_paginate li.last > a {
	position: relative;
	padding-right: 24px;
}

div.dataTables_paginate li.first a:before,
div.dataTables_paginate li.previous a:before {
	position: absolute;
	top: 6px;
	left: 10px;
	display: block;
  font-family: $font-awesome-family;
}

div.dataTables_paginate li.next a:after,
div.dataTables_paginate li.last a:after {
	position: absolute;
	top: 6px;
	right: 10px;
	display: block;
  font-family: $font-awesome-family;
}

div.dataTables_paginate li.first a:before {
	content: "\f100";
}

div.dataTables_paginate li.previous a:before {
	content: "\f104";
}

div.dataTables_paginate li.next a:after {
	content: "\f105";
}

div.dataTables_paginate li.last a:after {
	content: "\f101";
}

/* In Foundation we don't want the padding like in bootstrap */
div.columns div.dataTables_paginate li.first a:before,
div.columns div.dataTables_paginate li.previous a:before,
div.columns div.dataTables_paginate li.next a:after,
div.columns div.dataTables_paginate li.last a:after {
	top: 0;
}

div.dts {
  .dataTables_scroll {
    border:thin solid #d3d3d3;
    .dataTables_scrollHead {
      margin-top:-8px;
    }
    .dataTables_scrollBody {
      background:#fff;
      div.dts_label {
        display:none !important;
      }
    }
    .dataTables_scrollFoot {
      height:40px;
    }
  }
}
