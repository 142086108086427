.checkboxContainingDiv {
	& + .field-error, & + .field-error.field-error-checkboxes {
		clear:both;
	}
	.header {
		background:$pdc-grey;
		padding:10px;
		.toggle {
			margin-top:2px;
			position:relative;
			input[type="checkbox"] {
				display:none;
				margin:0;
				&:checked + label::after {
					border-color:$pdc-base-color;
				}
				&:checked + label::before {
					background-color:$pdc-base-color;
					-webkit-transform:translateX(8px);
				  -ms-transform:translateX(8px);
				  transform:translateX(8px);
				}
			}
			label {
				float:left;
				margin:0;
				padding-left:28px;
				position:relative;
				&::after {
					background-color:transparent;
					border:1px solid $pdc-body-copy;
					border-radius:15px;
					content:"";
				  cursor:pointer;
					height:16px;
					left:0;
					position:absolute;
					-webkit-transition:0.4s;
				  transition:0.4s;
				  top:2px;
					width:24px;
					z-index:1;
				}
				&::before {
					background-color:$pdc-body-copy;
					border-radius:12px;
				  content:"";
				  height:12px;
					left:2px;
					position:absolute;
					top:4px;
					-webkit-transition:0.4s;
				  transition:0.4s;
				  width:12px;
					z-index:2;
				}
			}
		}
		.checkboxClearAll {
			float:right;
		}
		.checkboxSelectAll {
			border-right:1px solid $pdc-body-copy;
			float:right;
			margin-right:10px;
			padding-right:10px;
		}
		@media (max-width:1000px) and (min-width:768px) {
		  .toggle {
				margin-bottom:10px;
		    width:100%;
		  }
		}
	}
	ul {
		list-style-type:none;
		margin-left:0;
		&.mainList {
			background:#ffffff;
			border:1px solid $pdc-grey;
			height:299px;
			margin:0;
			overflow-y:scroll;
		}
		li {
			float:left;
			width:100%;
			input[type="checkbox"] {
				display:none;
				&:checked + label {
					background:$pdc-base-color;
					color:$white;
					&::after {
						color:$white;
            font-family: $font-awesome-family;
						content:"\f058";
					}
					&:hover, &:focus {
						background:$pdc-hover-color;
					}
				}
			}
			label {
				display:inline-block;
				margin:0;
				padding:10px 30px 10px 15px;
				position:relative;
				vertical-align:bottom;
				width:100%;
				&::after {
					bottom:0;
					color:$pdc-grey;
					content:"\f111";
					cursor:pointer;
          font-family: $font-awesome-family;
					height:12px;
					line-height:12px;
					position:absolute;
					right:14px;
					text-align:center;
					top:15px;
					width:12px;
					z-index:1;
				}
				&:hover, &:focus {
					background:$pdc-hover-color;
					color:$white;
					&::after {

					}
				}
			}
			ul {
				float:left;
				width:100%;
				li {
					label {
						padding-left:30px;
					}
				}
			}
		}
	}
	@include breakpoint (small only){
		margin-bottom:1rem;
	}
}

a.helpBack {
	color:#ffffff;
	left:0;
	position:absolute;
	top:-30px;
	&:active, &:focus, &:hover {
		color:#ffffff;
	}
}
#flowDetail {
  hr {
    max-width:100%;
  }
  #detailHeader {
    .flowDetailName {
      font-size:x-large;
      font-weight:bold;
    }
    .flowDetailRetry {
      .dropdown-pane {
        margin-left:10px;
      }
    }
  }
  .expandOrCollapse {
    a {
      font-size:smaller;
    }
  }
  #detailNav .dropdown-pane, #detailMain .dropdown-pane:not(.pdc-help) {
    background:$pdc-body-copy;
    border:none;
    left:0 !important;
    margin:0 2%;
    top:0;
    max-width:96%;
    width:auto;
    padding:0.625rem;
    form {
      align-items:center;
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      -ms-flex-flow:row wrap;
      flex-flow:row wrap;
      & > div {
        margin:0;
        -webkit-box-flex:1;
        -ms-flex:1 1 0px;
        flex:1 1 0px;
        &#emailAddressEmaildiv, &#emailAddressVoiddiv, &#memoVoiddiv {
          display:block;
          margin-right:0.9375rem;
          .field-error {
            line-height:18px;
            margin:0;
          }
        }
        &#ajaxStatusajaxVoidMsg {
          line-height:18px;
          &.field-error {
            border-radius:3px;
            margin-right:0.9375rem;
          }
        }
      }
      input[type='email'], input[type='text'] {
        margin:0;
        width:326px;
      }
      button.button {
        margin:0;
        -webkit-box-flex:1;
        -ms-flex:1 1 140px;
        flex:0 0 140px;
        max-height:40px;
        width:140px;
        &:focus, &:active {
          border:none;
          outline:0;
        }
      }
      .field-error {
        a {
          color:$white;
          &:active, &:focus, &:hover {
            color:$black;
          }
        }
      }
    }
    @include breakpoint (medium only){
      &.void {
        form {
          & > div {
            &#emailAddressEmaildiv, &#emailAddressVoiddiv, &#memoVoiddiv {
              .field-error {
                width:216px;
              }
            }
          }
          input[type='email'], input[type='text'] {
            width:216px;
          }
        }
      }
    }
    @include breakpoint (small only){
      width:96%;
      form {
        display:block;
        width:100%;
        & > div {
          &#emailAddressEmaildiv, &#emailAddressVoiddiv, &#memoVoiddiv {
            margin:0 0 1rem 0;
          }
        }
        input[type='email'], input[type='text'] {
          width:100%;
        }
        button.button {
          display:block;
          width:100%;
        }
      }
    }
  }
  #detailNav {
    margin-bottom:30px;
    padding:0 5px;
    button.detailNavigate {
      @include border-1-solid-grey;
      border-top: none;
      border-radius: 0;
      color: $pdc-body-copy;
      font-size: 0.750rem;
      margin: 0;
      padding: 12px 15px;
      width: 100%;
      text-align: left;
      text-transform: inherit;
      &:first-of-type {
        border-top-color: $pdc-grey;
        border-top-style: solid;
        border-top-width: 1px;
        border-top-left-radius: $global-radius;
        border-top-right-radius: $global-radius;
      }
      &:last-child {
        border-bottom-left-radius: $global-radius;
        border-bottom-right-radius: $global-radius;
      }
      &:hover {
        background-color: $pdc-hover-color;
        color: $white;
      }
      &.active {
        background-color: $pdc-hover-color;
        color: $white;
      }
    }
    #fileBlock {
      $accordion-plus-content: '\f078';
      $accordion-minus-content: '\f077';
      $accordion-title-font-size: .75rem;
      $accordion-item-color: $pdc-body-copy;
      $accordion-item-background-hover: #997ad2;
      $accordion-item-padding: 12px 15px;
      $accordion-content-padding: 0;
      $accordionmenu-arrow-color: $pdc-body-copy;

      .accordion {
        @include accordion-container;
      }

      .accordion-item {
        @include accordion-item;
      }

      .accordion-item:not(:first-of-type) {
        margin: -1px 0;
      }

      .accordion-title {
        @include accordion-title(
          $padding: $accordion-item-padding,
          $font-size: $accordion-title-font-size,
          $color: $accordion-item-color,
          $background-hover: $accordion-item-background-hover
        );

        &:hover, &:focus {
          background-color: #997ad2;
          color: $white;
        }

        &::before {
          font-family:$font-awesome-family;
          font-weight:900;
          content: $accordion-plus-content;
        }
      }

      .accordion-item.is-active .accordion-title::before {
        content: $accordion-minus-content;
      }

      .accordion-content {
        @include accordion-content(
          $padding: $accordion-content-padding,
          $border: $accordion-content-border,
          $background: $accordion-content-background,
          $color: $accordion-content-color
        );

        margin: -1px 0;

        & .content {
          display:flex;
          flex-direction: column;
        }

        a {
          &:hover {
            background-color: #997ad2;
            color: $white;
          }

          font-size: .85rem;
          padding: 4px 12px;
        }
      }

      p {
        font-size:smaller;
        margin-bottom:0;
      }
      #fileBlockBox {
        border:1px solid $pdc-grey;
        padding:5px;
      }
    }
  }
  #detailMain {
    margin:0;
    padding:0 5px 0 10px;
    overflow:auto;
    .labelDetail {
      font-weight:bold;
    }
    .showContent:hover {
      cursor:pointer;
    }
    #sectionHeader {
      font-weight:bold;
    }
    hr.sectionDivider {
      background-color:$pdc-base-color;
      height:2px;
    }
    .row.transaction-historyTable {
      max-width:100%;
    }
    h6 {
      font-weight:bold;
    }
    div.even {
      background-color:$pdc-background-color;
    }
    img {
      padding:10px 0;
    }
    #creditTransaction {
      background:$pdc-background-color;
      padding:10px 15px 0;
    }
    .transactionContainer {
      margin-bottom:20px;
      position:relative;
      .transactionRows {
        background:$white;
        height:auto;
        line-height:42px;
        overflow-x:scroll;
        .hiddenRow {
          display:-webkit-box;
          display:-ms-flexbox;
          display:flex;
          -ms-flex-flow:row wrap;
          flex-flow:row wrap;
          line-height:normal;
          .hiddenDiv {
            margin:5px 0 15px 10px;
            -webkit-box-flex:1;
            -ms-flex:1 1 0px;
            flex:1 1 0px;
          }
        }
        .titleRow {
          background:$white-smoke;
          font-weight:bold;
          min-width:1000px;
        }
        .transactionActions {
          margin-left:10px;
          min-width:70px;
          width:6.140351%;
          a {
            color:$pdc-body-copy;
            margin-right:5px;
            &:hover, &:focus, &:active, &.hover {
              color:$pdc-hover-color;
            }
          }
        }
        .transactionCheckStatus {
          background:$white;
          line-height:20px;
          tbody, tfoot, thead {
            border: none;
            background: transparent;
            tr {
              border:1px solid $pdc-grey;
              &:nth-child(even) {
                background:$white;
              }
            }
          }
        }
        .transactionDate {
          margin-left:10px;
          min-width:200px;
          width:21.052632%;
        }
        .transactionDetails {
          display: block;
          width:42px;
          a {
            display:block;
            height:42px;
            text-align:center;
            width:42px;
            border:3px solid $pdc-base-color;
            &:hover, &:focus, &:active {
              border-color:$pdc-hover-color;
            }
          }
        }
        .transactionFee {
          margin-left:10px;
          min-width:100px;
          width:12.280702%;
        }
        .transactionID {
          margin-left:10px;
          min-width:110px;
          width:13.157895%;
        }
        .transactionPayment {
          margin-left:10px;
          min-width: 00px;
          width:13.157895%;
        }
        .transactionRow {
          min-width:1000px;
          &:nth-child(odd) {
            background:#f1f1f1;
          }
        }
        .transactionStatus {
          margin-left:10px;
          min-width:120px;
          width:10.526316%;
        }
        .transactionTotal {
          margin-left:10px;
          min-width:100px;
        }
      }
    }
    .signatureConfirmationContainer {
      border:1px solid $pdc-grey;
      box-sizing:border-box;
      padding:2%;
      position:relative;
      width:100%;
      .signatureConfirmationImage {
        margin:0 auto; position:relative;
        width:100%;
        z-index:4;
      }
      .signatureConfirmationLine {
        background:$pdc-grey;
        height:2px;
        position:absolute;
        top:70%;
        width:96%;
        z-index:1;
      }
      .signatureConfirmationXLeft {
        background:$pdc-grey;
        height:30%;
        left:8%;
        max-height:43px;
        max-width:4px;
        position:absolute;
        top:35%;
        transform:rotate(34deg);
        width:1%;
        z-index:2;
      }
      .signatureConfirmationXRight {
        background:$pdc-grey;
        height:30%;
        left:8%;
        max-height:43px;
        max-width:4px;
        position:absolute;
        top:35%;
        transform:rotate(-34deg);
        width:1%;
        z-index:2;
      }
    }
  }
}

.multiSelectLeft {
  & > .small-12 {
    & > .input-field {
      float:left;
    }
  }
}
#servicediv {
  margin-top:$global-margin;
}
#totals {
	table.dataTable {
		tr {
			th {
				text-align:left;
			}
		}
	}
}
#emailDetailEmailForm {
  .field-error {
    margin:0;
  }
}

#emailIframe {
  width: 100%;
  height: 40vh;
}

#charts_container {
  margin-bottom:20px;

  .cell {
    max-height: 300px;
  }
}

#searchForm {
  .grid-pdc {
    width: initial !important;
  }

  .reminderCard {
    .grid-bottom-section {
      padding-bottom: 0;
    }
  }

  .reminderTotals {
    font-size: larger;
  }
}