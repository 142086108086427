.help-dropdown {
  p {
    color: $pdc-body-copy;
    font-size: 0.750rem;
    text-transform: none;
  }
}

.pdc-checkboxes .pdc-check-item input:checked + label#pdc-select-all { background-color: $pdc-base-color; }

h4.recent-history {
  color: $pdc-body-copy;
  font-size: 1.100rem;
}
