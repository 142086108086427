#actionsButton.dropdown {
  margin:0;
  &::after {
    display:none;
  }
}
#actionsMenu.dropdown-pane {
  background:$white;
  padding:0;
  width:200px;
  ul {
    list-style-type:none;
    margin:0;
    padding:0;
    li {
      width:100%;
      a {
        display:block;
        padding:0.5rem 1rem;
        width:100%;
        &:active, &:focus, &:hover {
          background:$pdc-hover-color;
          color:$white;
        }
      }
    }
  }
}
#scheduleCreation {
  &.swiperActive {
    #scheduleForm {
      .input-field {
        .secureWrapper {
          .secureOverlay {
            width:calc(100% - 1.875rem - 35px);
          }
        }
      }
    }
  }
  &.detail {
    & > div {
      width:auto;
      &#scheduleForm {
        flex:0 1 auto;
        margin-right:1rem;
        .input-field {
          padding:0.25rem 0;
          label {
            line-height:1.5;
            margin-top:0;
          }
        }
      }
      &#schedulePayments {
        .dataTables_wrapper {
          .dataTables_scroll {
            .dataTables_scrollBody {
              table {
                tr {
                  td {
                    .dropdown-pane {
                      &.dropdown-pane-email {
                        background:#fff;
                        button {
                          margin-left: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  & > div {
		flex:1;
	}
	#scheduleForm {
		min-width:550px;
		#minimumTermsBox {
			border:$fieldset-border;
			border-radius:$global-radius;
			font-size:0.75rem;
			margin-top:-40px;
			padding:0.375rem 0.625rem;
			width:312px;
		}
    #contactInfo {
      phoneNumberConsentDiv {
        margin-left:6px;
        padding:3px;
      }
    }
		.input-field {
      #intervals {
				.interval {
					font-size:0.75rem;
					margin-left:0.625rem;
					margin-right:0;
          width:calc(100% - 0.625rem);
					&#intervalsBiMonthly, &#intervalsBI_MONTHLY {
						.cell {
							&.intervalsBiMonthlyAnd, &#intervalOptionTwoText {
								max-width:30px;
								min-width:30px;
							}
							&.intervalsBiMonthlyOn, &#intervalOptionOneText {
								max-width:34px;
								min-width:34px;
							}
						}
            .select-after {
              &:first-of-type {
                flex:0 1 50px;
              }
            }
					}
					&#intervalsBiWeekly, &#intervalsBI_WEEKLY {
						.cell {
							&.intervalsBiWeeklyAnd {
								max-width:10px;
								min-width:10px;
							}
							&.intervalsBiWeeklyOn {
								max-width:14px;
								min-width:14px;
							}
              &#intervalOptionOneText {
                flex:0 1 65px;
              }
						}
            .select-after {
              &:first-of-type {
                flex:0 1 120px;
                margin-right:0;
              }
            }
					}
          &#intervalsDaily, &#intervalsDAILY {
            .intervalsDailyEvery, #intervalOptionOneText {
              max-width:34px;
              min-width:34px;
            }
            .intervalsDailyDay, #intervalOptionTwoText {
              max-width:50px;
							min-width:50px;
						}
            .select-after {
              &:first-of-type {
                flex:0 1 50px;
              }
            }
					}
					&#intervalsMonthly, &#intervalsMONTHLY {
            .intervalsMonthlyEvery, #intervalOptionOneText {
              max-width:34px;
              min-width:34px;
            }
            .intervalsMonthlyMonth, #intervalOptionTwoText {
              max-width:100%;
							min-width:50px;
						}
            .select-after {
              &:first-of-type {
                flex:0 1 40px;
              }
              &:last-of-type {
                flex:0 1 80px;
              }
            }
					}
          &#intervalsWeekly, &#intervalsWEEKLY {
            .intervalsWeeklyEvery, #intervalOptionOneText {
              max-width:34px;
              min-width:34px;
            }
            .intervalsWeeklyDay, #intervalOptionTwoText {
              max-width:50px;
							min-width:50px;
						}
            .select-after {
              &:first-of-type {
                flex:0 1 50px;
              }
            }
					}
					.cell {
						flex:1;
						margin-left:0;
						&:last-child {
							margin-right:0;
						}
            &.select-after {
              .select2-container {
                .select2-selection--single {
                  padding-left:0;
                  padding-right:12px;
                }
              }
              select {
                padding-left:0;
                padding-right:12px;
              }
            }
					}
				}
			}
      .secureWrapper {
        align-items:center;
        .secureOverlay {
          margin:0 0.625rem;
          min-width:50%;
        }
      }
		}
	}
  #schedulePayments.add {
    background-color:#fff;
    padding: 10px;
    #paymentPreviewSummary {
      background-color:$pdc-grey;
      padding:5px;
      width:100%;
    }
  }
  #schedulePayments #requiredTextContainer {
    margin-bottom:30px;
    #requiredText .requiredText {
      left:55px;
      width:calc(100% - 55px);
    }
  }
}
@include breakpoint(small only) {
	#scheduleCreation {
		& > div {
			flex:auto;
		}
	}
}
#pdcForm {
	#scheduleCreation {
		justify-content:center;
		#scheduleForm {
			max-width:575px;
		}
	}
}
#editor.scheduleConfig {
  #tabsLists {
    .tabs-panel#settingsList {
      h5:first-of-type {
        margin-top:0;
      }
    }
  }
}
