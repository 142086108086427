#formDesigner {
	border:1px solid $pdc-grey;
	display:flex;
	height:840px;
	position:relative;
	overflow:hidden;
}
#editor {
	background:$pdc-background-color;
	border-right:1px solid $pdc-grey;
	height:100%;
	overflow:hidden;
	position:absolute;
	width:340px;
	z-index:3;
	form {
		height:100%;
		margin:0;
		overflow:scroll;
	}
	#editorTabs {
		background:$pdc-grey;
		border:none;
		box-shadow:0 1px 8px $pdc-background-color;
		left:0;
		position:absolute;
		top:0;
		width:100%;
		z-index:101;
		&.twoTabs {
			li.tabs-title {
				width:50%;
			}
		}
		li.tabs-title {
			border-right:1px solid $pdc-background-color;
			text-align:center;
			width:33.333%;
			a {
				background:$pdc-grey;
				font-size:1.2rem;
				outline:none;
				padding:1rem 1.5rem;
				&:hover, &:focus {
					color:$pdc-hover-color;
				}
			}
			& > a:focus, & > a[aria-selected='true'] {
				background:$pdc-background-color;
				color:$pdc-body-copy;
				outline:none;
			}
			&:last-child {
				border-right:none;
			}
		}
	}
	#tabsLists {
		padding-top:120px;
		.tabs-panel {
			padding:10px 15px 60px;
			&#settingsList {
				h5 {
					margin-top:1.5rem;
				}
			}
			&#fieldsList {
				padding-bottom:35px;
        #documentFields {
          #documentAccordion .accordion-content {
            padding: 0;
          }

          #documents-data {
            display: none;
          }

          #documents-container {
            .document-record {
              border:1px solid #d0d0d0;
              padding:12px;

              #overlayLabel {
                display: flex;
                justify-content: space-between;

                & > div {
                  font-size: 0.75rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 4px;
                }
              }

              .fileUpload {
                margin-bottom:1rem;
              }
            }
          }
        }

        #imageFields {
          .file-requests-container,
          #file-requests-container {
            margin-top: 2rem;
            width: 100%;
            .file-request {
              width: 100%;
            }
          }
        }
        .add-delete-controls {
          display: flex;
          justify-content: space-between;
        }
			}
			&#stylesList {
				h5 {
					margin-top:0.5rem;
					&:first-of-type {
						margin-top:0;
					}
				}
			}
			&:after {
				clear:both;
				content:"";
				display:block;
			}
		}
		.input-field {
			margin-bottom:0;
			&#portalDescriptiondiv, &#customPortalTextdiv {
				margin-bottom:1rem;
				.field-error-textarea {
					margin-bottom:0;
					margin-top:0 !important;
				}
			}
			span.counter {
				line-height:1.8;
				margin:-21px 1px 0;
				padding:0 5px;
			}
			.select-after {
				select {
					option{
						&:disabled {
							color:$pdc-grey;
						}
					}
				}
			}
		}
		.dropdown-pane {
			p {
				margin:0;
			}
		}
	}
	#searchFields {
		background:$pdc-background-color;
		box-shadow:0 1px 8px $pdc-background-color;
		left:0;
		padding-top:1rem;
		position:absolute;
		top:51px;
		width:100%;
		z-index:9;
		.input-group-label {
			color:$pdc-base-color;
			font-size:1rem;
		}
	}
	.accordionToggle {
		float:right;
		font-size:0.75rem;
		margin-top:8px;
		a {
			float:right;
			&:first-child {
				border-left:1px solid $pdc-grey;
				margin-left:10px;
				padding-left:10px;
			}
		}
	}
	.accordion {
		background:transparent;
		margin-bottom:1.5rem;
	}
	.accordion-item {
		position:relative;
		label {
			a.pdc-helper-icon {
				position:relative;
			}
		}
		a.pdc-helper-icon {
			font-size:0.750rem;
			line-height:1;
			padding:1rem 0 0 0;
			position:absolute;
			z-index:2;
			& + .dropdown-pane {
				& + .accordion-title {
					padding:1rem 0 1rem 14px;
				}
			}
		}
	}
	.accordion-title {
		background:transparent;
		border:none;
		border-bottom:1px solid $pdc-grey;
		border-radius:0;
		color:$pdc-body-copy;
		cursor:pointer;
		font-size:0.750rem;
		padding:1rem 0;
		text-transform: uppercase;
		&:focus {
			outline:none;
		}
		label {
			cursor:pointer;
			line-height:1;
			a.pdc-helper-icon {
				margin-right:4px;
				padding:0;
				position:relative;
			}
		}
	}
	.field-error-upload {
		margin-top:0!important;
	}
	div.accordion-title {}
	.accordion-content {
		background:transparent;
		border:1px solid $pdc-grey;
		border-radius:0;
		border-top:none;
		color:$pdc-body-copy;
		padding:1rem 1rem 0;
	}
	.sp-replacer.sp-light {
		background:$white;
		border:1px solid $medium-gray;
		border-right:none;
		border-radius:3px 0 0 3px;
		height:2.4375rem;
		padding:0.65rem;
		width:100%;
		.sp-preview {
			border-color:$pdc-body-copy;
			height:100%;
			margin:0;
			width:100%;
		}
		.sp-dd {
			display:none;
		}
	}
	.radio-group {
		margin-bottom:1rem;
		label {
			input {
				visibility:hidden;
			}
		}
	}
	input.hex {
		border-radius:0 3px 3px 0;
	}
	.rangeSlider {
		.float-left {
			margin:0.2rem 0 1rem;
		}
		input[type="range"] {
			-webkit-appearance:none;
			background:transparent;
			border:none;
			margin:0;
			padding:0;
			&:active,&:focus,&:hover {
		    outline: none;
				&::-webkit-slider-thumb {
					background:linear-gradient($pdc-hover-color,$pdc-base-color);
					border-color:$pdc-base-color;
				}
				&::-moz-range-thumb {
					background:linear-gradient($pdc-hover-color,$pdc-base-color);
					border-color:$pdc-base-color;
				}
				&::-ms-thumb {
					background:linear-gradient($pdc-hover-color,$pdc-base-color);
					border-color:$pdc-base-color;
				}
			}
			&::-webkit-slider-runnable-track {
				background-color:$white;
				border:1px solid $medium-gray;
				border-radius:$global-radius;
				height:15px;
			}
			&::-webkit-slider-thumb {
				-webkit-appearance:none;
				background:linear-gradient($white,$medium-gray);
				border:1px solid $secondary-color;
				border-radius:20px;
				height:13px;
				width:13px;
        margin-top:0.5px;
			}
			&::-moz-range-track {
				background:$white;
				border:1px solid $medium-gray;
				border-radius:$global-radius;
			  height:15px;
				width:100%;
			}
			&::-moz-range-thumb {
				background:linear-gradient($white,$medium-gray);
				border:1px solid $secondary-color;
				border-radius:20px;
				height:13px;
				width:13px;
			}
			&:-moz-focusring{
		    outline:none;
			}
			&::-ms-track {
				background-color:$white;
				border:1px solid $medium-gray;
				border-radius:$global-radius;
				color:transparent;
				height:15px;
			}
			&::-ms-fill-lower {
				background-color:$white;
				border-radius:$global-radius;
				color:transparent;
				height:15px;
				width:100%;
			}
			&::-ms-fill-upper {
				background-color:$white;
				border-radius:$global-radius;
				color:transparent;
				height:15px;
				width:100%;
			}
			&::-ms-thumb {
				background:linear-gradient($white,$medium-gray);
				border:1px solid $secondary-color;
				border-radius:20px;
				box-sizing:border-box;
				height:13px;
				width:13px;
			}
		}
		.rangeValue {
			line-height:1.2;
		}
	}
	footer {
		background:$pdc-grey;
		bottom:0;
		display:flex;
		height:50px;
		left:0;
		position:absolute;
		width:100%;
		z-index:101;
		#ajaxStatusMsg, #ajaxStatusajaxFormMsg {
			left:0;
			position:absolute;
			top:-21px;
			width:100%;
			z-index:100;
		}
		.button {
			border-radius:0;
			font-size:20px;
			line-height:2.4;
			margin:0;
			padding:0;
			&:focus {
				outline:none;
			}
			&.secondary:focus, &.secondary.hover {
				background:$white-smoke;
				color:$pdc-body-copy;
			}
		}
		.dropdown-pane {
			background:$white-smoke;
			border-bottom:none;
			border-color:$dark-gray;
			border-radius:0;
			left:0 !important;
			padding:0;
			text-align:center;
			width:100%;
			.button-group {
				margin:0;
				&.secondary button {
					background:$white-smoke;
					border-bottom:1px solid $dark-gray;
					border-left:1px solid $dark-gray;
					border-right:1px solid $dark-gray;
					border-top:none;
					color:$pdc-grey;
					line-height:1.6;
					&:first-child, &:last-child {
						border-left:none;
						border-right:none;
					}
					&:hover, &:focus, &.active {
						background:$pdc-body-copy;
						color:$white;
					}
				}
				&#previewSizes {
					&.secondary button {
						font-size:1.9375rem;
					}
				}
				&#formCodes {
					&.secondary button {
						font-size:1rem;
						line-height:3rem;
					}
				}
			}
			.button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
				width:33.33333%;
			}
			#previewInfo {
				padding:1.8rem 1rem;
				h3 {
					margin:0;
				}
			}
			.tabs {
				background:$white-smoke;
				border:none;
				border-bottom:1px solid $dark-gray;
				transition:none;
				display:flex;
				li.tabs-title {
					border-left:1px solid $dark-gray;
					border-right:1px solid $dark-gray;
					width:100%;
					&:first-child, &:last-child {
						border-left:none;
						border-right:none;
					}
					a {
						color:$pdc-body-copy;
						font-size:1rem;
						line-height:3;
						padding:0;
						transition:none;
						&:hover,&[aria-selected='true'] {
							background:$pdc-body-copy;
							color:$white;
						}
					}
				}
				&#previewSizes {
					display:flex;
					li.tabs-title {
						a {
							font-size:1.9375rem;
							line-height:1.6;
						}
					}
				}
			}
			.tabs-content {
				transition:none;
				.tabs-panel {
					padding:1rem;
					p {
						margin:1rem 0 0.5rem;
						&:first-child {
							margin-top:0;
						}
					}
					.callout {
						border-radius:3px 3px 0 0;
						margin:0;
						word-break:break-all;
					}
					.button {
						font-size:inherit;
					}
				}
				&#formCodeInfo {
					text-align:left;
				}
			}
		}
	}
}
#preview {
	background:#fefefe;
	height:100%;
	margin-left:340px;
	overflow:hidden;
	position:relative;
	width:100%;
	z-index:1;
	#sizer {
		height:100%;
		margin:0 auto;
		width:100%;
	}
	#pdcForm {
		height:100%;
		margin-top:0;
		overflow-x:hidden;
		overflow-y:scroll;
		padding:10px;
		width:100%;
    /* portal designer */
    &.button {
      width:auto;
    }
    .button.buttonSecondary {
      border: thin solid #4c2d86;
    }
    /* card, check, cash, flow designer */
    .submit-box {
      button {
        width:auto;
        &.buttonSecondary {
          border: thin solid $pdc-base-color;
        }
      }
    }
		&.creditCard, &.check {
			.paymentInfo {
				#div-card-paymentType, #div-check-paymentType {
					display:none;
				}
			}
			&.combined {
				.paymentInfo {
					#div-card-paymentType, #div-check-paymentType {
						display:block;
					}
				}
			}
		}
		&.flowForm {
			div.input-field {
				margin-bottom:0;
			}
      .uploadDropContainer {
        padding-top: 0;
      }

      .fileUploadReport {
        gap: 12px;

        .cell.odd {
          margin-left: 8px;
        }

        .fileUploadDrop {
          .button {
            color:#4c2d86;
          }
          label {
            padding: 13px;
            p .button {
              width:fit-content;
              height:auto;
            }
          }
        }
        .fileRecord {
          padding: 10px;
          margin: 0;

          .optionDropdown {
            button {
              width: 16.6666666667%;
              display: flex;
            }
          }
          .overlayRow {
            margin-top: 12px;
            & > div {
              margin:0 4% 0 0;
              width:48%;
              &:last-child {
                margin:0;
              }
            }
          }
        }
      }
		}
		> div {
			position:relative;
			#formBlocker, .formBlocker {
				height:100%;
				position:absolute;
				width:100%;
				z-index:100;
			}
		}
    .select-after {
			&.readonly {
        .select2-container {
          .select2-selection--single {
            border:none;
            font-style:italic;
          }
        }
			}
      .select2-container {
        margin:0;
        &.select2-container--disabled {
          .select2-selection--single {
            background-color: #e8e8e8;
            border-color: #d0d0d0;
          }
        }
        .select2-selection--single {
          appearance:none;
          background-color:$input-background;
          border:$input-border;
          border-radius:$input-radius;
          box-shadow:$input-shadow;
          box-sizing:border-box;
          color:$input-color;
          display:block;
          font-family:$input-font-family;
          font-size:$input-font-size;
          font-weight:$input-font-weight;
          height:40px;
          line-height:$input-line-height;
          padding:$input-padding;
          transition:$input-transition;
          width:100%;
          &:disabled {
            background-color: $pdc-background-color;
            border-color: $pdc-grey;
            &:hover {
              background-color: $pdc-background-color;
              border-color: $pdc-body-copy;
            }
          }
          .select2-selection__rendered {
            padding-left:0;
          }
        }
      }
      .select2-container--default {
        .select2-selection--single{
          .select2-selection__arrow {
            display:none;
          }
          .select2-selection__rendered {
            color:inherit;
            line-height:inherit;
          }
        }
      }
    }
	}
	#hideEditor {
		border-radius:0 3px 3px 0;
		bottom:0;
		font-size:20px;
		height:50px;
		left:0;
		line-height:2.4;
		margin:0;
		padding:0;
		position:absolute;
		width:30px;
		z-index:100;
	}
	#formDisplay {
		background:$pdc-background-color;
		border:1px solid $pdc-grey;
		border-bottom:none;
		border-radius:3px 3px 0 0;
		bottom:0;
		display:flex;
		left:50%;
		margin-left:-250px;
		position:absolute;
		width:500px;
		& > span {
			padding:5px 15px;
			float:left;
			text-align:center;
			width:25%;
		}
		label {
			float:left;
			width:25%;
			input {
				left:-9999px;
				position:absolute;
				&:checked + span {
					color:$pdc-base-color;
					&::before {
						color:$pdc-base-color;
						content: '\f192\0020';
					}
				}
			}
			span {
				display:block;
				padding:8px 15px;
				text-align:center;
				text-transform:capitalize;
				&::before {
					content: '\f10c\0020';
          font-family: $font-awesome-family;
				}
			}
		}
	}
	.helper {
		float:left;
		font-size:12px;
		line-height:inherit;
		padding-right:5px;
	}
	&.dashboard {
		#sizer {
			#pdcForm {
				&.creditCard {
					#cardForm {
						display:flex;
						&.grid-margin-x, &.grid-margin-y, &.grid-padding-x, &.grid-padding-y {
							.grid-margin-x, .grid-margin-y, .grid-padding-x, .grid-padding-y {
								margin:0;
							}
						}
						#cardAccountInfo, #cardPaymentInfo, #cardAccountType {
							width:100%;
						}
						.cell#div-card-cardNumber {
							min-width:40%;
							#cardNumberFlex {
								display:flex;
								.swipeButton {
									margin-left:8px;
									margin-top:4px;
								}
							}
						}
						.cell#cardExpiration {
							min-width:40%;
							> div {
								margin:0;
								padding:0;
								width:100%;
								> div {
									float:left;
									margin:0 4% 0 0;
									width:48%;
									&:last-child {
										margin:0;
									}
								}
							}
						}
						.cell#div-card-cardCode {
							min-width:20%;
						}
						.cell#div-card-postAuthorization, .cell#div-card-accountDirective {
							width:50%;
						}
					}
				}
				&.check {
					#checkForm {
						display:flex;
						&.grid-margin-x, &.grid-margin-y, &.grid-padding-x, &.grid-padding-y {
							.grid-margin-x, .grid-margin-y, .grid-padding-x, .grid-padding-y {
								margin:0;
							}
						}
						#checkBankAccountType {
							float:left;
							width:100%;
						}
						#checkBankInfo, #checkPaymentInfo {
							flex-flow:row wrap;
						}
						#checkPaymentInfo {
							width:100%;
						}
						.fieldCombine {
							.cell {
								flex:1 1;
							}
						}
						.formElement#div-check-bankAccountNumber, .formElement#div-check-bankAccountNumberConfirm, .formElement#div-check-bankRoutingNumber, .formElement#div-check-checkNumber, .formElement#div-check-paymentAmount, .formElement#div-check-feeAmount, .formElement#div-check-totalAmount, .formElement#div-check-dateScheduled {
							width:50%;
							flex:0 0 auto;
						}
					}
				}
				&.flowForm {
					.grid-margin-x, .grid-margin-y, .grid-padding-x, .grid-padding-y {
						.grid-margin-x, .grid-margin-y, .grid-padding-x, .grid-padding-y {
							margin:0;
						}
					}
					.grey-line {
						margin:0;
						padding:0;
					}
					.pdc-checkboxes {
						margin:0;
					}
					.submit-box {
						margin:0;
					}
					#div-flow-requestedPaymentType {
						width:100%;
					}
					#div-flow-timeoutSelector, #div-flow-cardExpYear {
						margin-top:21px;
					}
					#checkBankInfo {
						flex-flow:nowrap;
						> div {
							flex-shrink:1;
						}
					}
					#flowCommon {
						margin:0;
						width:100%;
					}
          #flowPayment {
            #sampleFileDownload {
              margin-top: 20px;
            }
          }
					#flowDocument {
            &.cell {
              padding: 0;
            }
            .helperLabel {
              padding-inline: 0.625rem;
            }
						.image-info {
							margin:0;
						}
						input {
							position:absolute;
						}
					}
          .includeImage {
            #file-requests-container-preview {
              margin-top: 2rem;
              width: 100%;
            }
            .file-request-preview {
              width: 100%;
              .request-num {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                p {
                  margin-bottom: 8px;
                }
              }
            }
          }
				}
				label {
					text-transform:uppercase;
				}
				.pdc-check-item {
					input + label {
						&:hover {
				      color: $white;
				    }
					}
					input:checked + label {
						color:$white;
					}
				}
				.paymentInfo {
					hr {
						margin:1.25rem 0.9375rem;
					}
				}
				.radio-group {
					input {
						position:absolute;
					}
				}
				.title {
					font-size:16px;
				}
			}
			&.smallForm {
				#pdcForm {
					.formElement {
						&.fieldCombine {
							display:block;
						}
					}
					&.creditCard {
						#cardForm {
							.formElement#div-card-cardNumber {
								width:100%;
							}
							.formElement#cardExpiration {
								width:100%;
							}
							.formElement#div-card-cardCode {
								width:100%;
							}
							.formElement#div-card-postAuthorization, .formElement#div-card-accountDirective {
								width:100%;
							}
						}
					}
					&.check {
						#checkForm {
							.formElement#div-check-bankAccountNumber, .formElement#div-check-bankAccountNumberConfirm, .formElement#div-check-bankRoutingNumber, .formElement#div-check-checkNumber, .formElement#div-check-paymentAmount, .formElement#div-check-feeAmount, .formElement#div-check-totalAmount, .formElement#div-check-dateScheduled {
								width:100%;
							}
						}
					}
					&.flowForm {
						.cell {
							width:100% !important;
							&#div-flow-timeout {
								.cell {
									width:50% !important;
								}
							}
						}
					}
				}
			}
			&.mediumForm {
				#pdcForm {
					&.creditCard {
						#cardForm {
							#cardAccountInfo, #cardPaymentInfo {
								display:flex;
							}
						}
					}
					&.check {
						#checkForm {
							#checkPaymentInfo {
								display:flex;
							}
						}
					}
				}
			}
		}
	}
}
.tooltip.resetForm, .tooltip.previewWidth, .tooltip.formCode, .tooltip.saveForm, .tooltip.hideEditor {
	background:#5f5f5f;
	&.right::before {
		border-color:transparent #5f5f5f transparent transparent;
	}
}
.sp-container.sp-light {
	background:$pdc-body-copy;
	border-color:$pdc-grey;
	border-radius:3px;
	font-family:inherit;
	width:309px;
	.sp-palette-container {
		border:none;
		border-bottom:1px solid $pdc-grey;
		box-sizing:border-box;
		margin:0;
		padding:1rem;
		width:100%;
		.sp-palette {
			box-sizing:border-box;
			max-width:100%;
			.sp-thumb-el {
				height:21px;
				width:21px;
				&:hover, &.sp-thumb-active {
					border-color:#ffffff;
				}
			}
		}
	}
	.sp-picker-container {
		border:none;
		box-sizing:border-box;
		margin:0;
		padding:1rem;
		width:100%;
		.sp-color, .sp-hue, .sp-clear {
			border:1px solid $medium-gray;
			font-family:inherit;
		}
		.sp-dragger {
			border:2px solid $white;
			background:$pdc-body-copy;
		}
		.sp-slider {
			background:transparent;
			border-color:$white;
			border-width:2px;
			left:-3px;
			opacity:1;
			right:-3px;
		}
		.sp-input-container {
			float:right;
			margin:0;
			width:50%;
			input.sp-input {
				-webkit-appearance:none;
				-moz-appearance:none;
				background-color:$white;
				border:1px solid $medium-gray;
				border-radius:0 3px 3px 0;
				box-shadow:$input-shadow;
				box-sizing:border-box;
				color:$pdc-body-copy;
				display:block;
				font-family:inherit;
				font-size:1rem !important;
				height:41px;
				margin:0;
				padding:0.5rem;
				transition:box-shadow 0.5s, border-color 0.25s ease-in-out;
				width:100%;
			}
			input:hover.sp-input, input:focus.sp-input {
				background-color:lighten($pdc-base-color, 62%);
				border-color:$pdc-hover-color;
			}
		}
		.sp-initial {
			border-color:$medium-gray;
			border-radius:3px 0 0 3px;
			box-sizing:border-box;
			height:41px;
			width:100%;
			span {
				height:39px;
				width:50%;
				&.sp-thumb-inner {
					width:100%;
				}
			}
		}
		.sp-cancel {
			color:$white !important;
			display:none;
		}
		.sp-button-container {
			margin-top:1rem;
			width:100%;
		}
		button {
			-webkit-appearance:none;
			-moz-appearance:none;
			background:$pdc-base-color;
			border:none;
			border-radius:3px;
			color:$white;
			display:inline-block;
			font-family:inherit;
			font-size:0.9rem;
			padding:0.85rem 1rem;
			margin:0;
			text-shadow:none;
			transition:background-color .25s ease-out,color .25s ease-out;
			width:100%;
			&:hover {
				background:$pdc-hover-color;
				border:none;
				text-shadow:none;
			}
		}
	}
}

#hierarchyConfiguration {
  #formDesigner {
    #editor {
      #tabsLists {
        padding-top:0;
        .tabs-panel {
          &#settingsList {
            h5 {
              margin-top:0.5rem;
              .pdc-helper-icon {
                float:right;
              }
            }
            #achDirectiveList, #cardDirectiveList, #cashDirectiveList {
              margin:-10px 0 1rem;
              .defaultColumn {
                margin-bottom:10px;
              }
              .directive {
                margin-bottom:10px;
                position:relative;
                label {
                  font-size:1rem;
                  font-weight:bold;
                  line-height:1.5;
                  text-transform:none;
                  input {
                    left:-9999px;
                    position:absolute;
                    &:checked {
                      & + span {
                        &::before {
                          content:'\f192\0020';
                        }
                      }
                    }
                  }
                  span {
                    display:flex;
                    padding-right:24px;
                    width:100%;
                    &::before {
                      content:'\f10c\0020';
                      font-family: $font-awesome-family;
                      font-weight:normal;
                      padding:0 30px 0 20px;
                    }
                  }
                }
                .directiveDelete {
                  line-height:1.5;
                  position:absolute;
                  right:0;
                  top:0;
                }
                .field-error {
                  border-radius:3px;
                  margin:0;
                }
              }
            }
            #locationIdList {
              .locationListItem {
                &.formBlocker {
                  pointer-events:none;
                  opacity:0.5;
                  -webkit-user-select:none; /* Chrome all / Safari all */
                  -moz-user-select:none; /* Firefox all */
                  -ms-user-select:none; /* IE 10+ */
                  user-select:none; /* Likely future */
                }
                h6 {
                  display:flex;
                  margin-bottom:10px;
                  b {
                    flex:auto;
                    padding-right:10px;
                  }
                  span {
                    flex:0 1 auto;
                  }
                }
                .locationAddress {
                  display:none;
                }
              }
            }
            .input-field {
              &.grid-x {
                .cell {
                  button {
                    height:40px;
                    width:100%;
                  }
                }
              }
              .expandingArea {
                & + .counter {
                  padding-bottom:1rem;
                }
              }
            }
          }
        }
      }
    }
    #preview {
      #scroller {
        height:100%;
        overflow-y:auto;
        width:100%;
        #previewWaiting {
          margin:20px 0;
        }
      }
    }
  }
}

#messagesEditor {
	#editor {
		#tabsLists {
			.input-field {
				.field-error-textarea {
					margin-top:0 !important;
				}
			}
		}
	}
  #preview {
    #scroller {
      height:100%;
      overflow-y:auto;
      width:100%;
      #scrollerContent {
        position:relative;
      }
    }
  }
}

#portalConfiguration {
  #formDesigner {
    #editor {
      #formCodeInfo {
        max-height:500px;
        overflow-y:scroll;
      }
      #tabsLists {
        .dropdown-pane.void {
          background:$pdc-body-copy;
          border:none;
          button {
            &.secondary {
              background:$pdc-grey;
              border-color:$pdc-lighter-copy;
              color:$pdc-base-color;
              &:active, &:focus, &:hover {
                background:$white-smoke;
                border-color:$white-smoke;
                outline:none;
              }
            }
          }
        }
        .variables {
          margin-bottom:1rem;
          position:relative;
          .showMessageVariables {
            font-size:0.750rem;
            line-height:1.8;
            position:absolute;
            right:0;
            top:0;
          }
        }
        .tabs-panel {
          &#settingsList {
            #locationIdList {
              .locationListItem {
                &.formBlocker {
                  pointer-events:none;
                  opacity:0.5;
                  -webkit-user-select:none; /* Chrome all / Safari all */
                  -moz-user-select:none; /* Firefox all */
                  -ms-user-select:none; /* IE 10+ */
                  user-select:none; /* Likely future */
                }
                h6 {
                  display:flex;
                  margin-bottom:10px;
                  b {
                    flex:auto;
                    padding-right:10px;
                  }
                  span {
                    flex:0 1 auto;
                  }
                }
                .locationAddress {
                  display:none;
                }
              }
            }
          }
        }
      }
    }
  }
}
#overlayDesigner, #overlayPreviewDiv {
  .radio-group label {
    width:100%;
    i {
      width:20px;
      text-align:center;
    }
  }
  .anOverlayBox i {
    padding:5px;
    pointer-events:none;
  }
  #overlayElementdiv .radio-group label span::before, #overlayElementdiv input:checked+span::before {
    content: "";
  }
  #formForUploading, #formForSaving {
    padding:20px 0;
  }
  #formForSaving {
    height:calc(100vh - 90px);
    button {
      margin:0;
      width:100%;
    }
    #ajaxLoadOverlayDiv {
      .selectedBorder {
        border: thick solid $pdc-body-copy;
      }
    }
    #detailHeader {
      #documentNameContainer {
        font-size:larger;
        margin-bottom:-15px;
      }
      #overlayNavigation {
        font-size:larger;
        overflow:auto hidden;
        text-align:right;
        &.verticalCenter {
          margin-top:18px;
        }
        #pageNumberOutput {
          padding:0 10px;
          .pageNumber {
            padding:10px;
            &.currentPage {
              background:$pdc-base-color;
              color:$white-smoke;
            }
          }
        }
      }
      #pageNumberOutput .select2 {
        width:fit-content !important;
      }
    }
    #detailNav {
      overflow:hidden;
    }
    #detailMain {
      padding:0;
    }
    #rightNav {
      position:relative;
      .bottomContent {
        bottom:0;
        left:0;
        margin-left:auto;
        margin-right:auto;
        position:absolute;
        right:0;
        width:90%
      }
    }
  }
}
