header {
  z-index:70;
  &#desktop-nav {
    background-color:$white;
    @include breakpoint(small down) {
      display: none;
    }
  }
  section.sticky {
    background-color: $white;
    border-bottom: 1px solid $pdc-grey;
    width: 100%;
    z-index: 50;
    div.row {
      background-color: $white;
    }
  }
  .sticky.is-anchored {
    height: inherit;
  }
  .top-bar, .top-bar ul {
    background-color: $white;
  }
  .top-bar input {
    margin: 15px;
    width: 89%;
  }
  .menu {
		align-items:center;
    li {
      a {
        color: $pdc-base-color;
        font-size: 0.850rem;
        text-transform: uppercase;
      }
			&.headerLogo {
				align-items:center;
				display:flex;
				height:60px;
				a {
					align-items:center;
					display:flex;
					height:100%;
					margin-right:0.25rem;
					padding:0 1rem;
				}
				> img {
					padding:0 1rem;
				}
			}
      &.has-submenu {
        a::after {
          content: '\f078';
          font-family: $font-awesome-family;
					margin-top:-8px;
          position: absolute;
          top: 50%;
          right: 0;
        }
        ul.is-dropdown-submenu.first-sub {
          li a::after {
            display: none;
          }
					li.is-dropdown-submenu-parent {
            &.opens-right {
              > a::after {
                border-color: transparent;
              }
            }
						> a::after {
		          content: '\f054';
							display: inline-block;
              font: var(--fa-font-solid);
							margin-top: -12px;
		          position: absolute;
							right: 15px;
		          top: 50%;
		        }
					}
        }
        &.is-active {
          a:focus {
            outline: none;
          }
        }
      }
    }
  }
  li.is-submenu-item, .dropdown-pane ul li {
    a {
      font-size: 0.850rem;
      padding: 15px !important;
      &:hover, &:active {
        background-color: $pdc-hover-color;
        color: $white;
      }
    }
  }
  .submenu {
		margin-top: 20px;
	}
  #emulate {
    font-size: 1.00rem;
		.select-after {
			&::after {
				right: 20px;
				top: 22px;
			}
		}
    select {
      margin-top: 10px;
      margin-left: 10px;
      width: 94%;
    }
  }
  .pane-holder {
    position: relative;
    .dropdown-pane {
      top: 10px !important;
      right: 0 !important;
      left: auto !important;
      width: 350px !important;
    }
  }
  .dropdown-pane {
    background-color: $white;
    border-radius: 0;
    padding: 0;
    ul {
      list-style: none;
      margin: 0;
      li {
        a {
          color: $pdc-base-color;
          display: block;
          text-transform: uppercase;
          width: 100%;
          transition: 0s ease;
        }
      }
    }
    input {
      margin-top: 15px;
      margin-left: 15px;
      position:relative;
      width: calc(100% - 30px);
      z-index:1;
    }
    p {
      font-size: 0.850rem;
      margin-bottom: 5px;
      padding: 0 10px;
    }
    #emulateIdMsg {
      margin-bottom:15px;
      margin-left:15px;
      position:relative;
      width:calc(100% - 30px);
      z-index:0;
    }
  }
  .user-menu {
    cursor: pointer;
    color: $pdc-base-color;
    font-size: 0.850rem;
    margin-top: 28px;
    padding-right: 20px;
    &:hover {
      color: $pdc-hover-color;
    }
    #company-name {
      color: $pdc-body-copy;
      font-size: 0.750rem;
    }
  }
	.stored-company {
		font-size: 0.850rem;
    margin-top: 28px;
    padding-right: 20px;
	}
  &#mobile-nav {
    background-color: $white;
    border-bottom: 1px solid $pdc-grey;
    display: none;
    @include breakpoint(small only) {
      display: block;
    }
		#mobileLogo {
			align-items:center;
			display:flex;
			float:left;
			height:58px;
			a {
				align-items:center;
				display:flex;
				height:100%;
			}
		}
		.stored-company-mobile {
			font-size: 0.850rem;
			margin-bottom: 18px;
			margin-top: 18px;
			max-width: 50%;
		}
    .nav-icon-toggle {
      background-color: $pdc-base-color;
      color: $white;
      cursor: pointer;
      font-size: 1.500rem;
      margin-right: -15px;
      padding: 11px 20px;
      &:hover, &:focus, &:active {
        background-color: $pdc-hover-color;
      }
			&.opened {
				background-color: $pdc-hover-color;
				i.fa-bars:before {
					content: "\f00d";
				}
			}
			i {
				width: 20px;
			}
    }
  }
}
nav {
  &.hidden-drilldown {
    background-color: $white;
    display: none;
    ul.menu {
      li {
        a {
          background-color: $white;
          color: $pdc-base-color;
          padding: 15px 20px;
          text-transform: uppercase;
          &:hover{
            background-color: $pdc-hover-color;
            color: $white;
						#company-name {
							color: $white;
						}
          }
          &.is-active, &:focus {
            background-color: $pdc-base-color;
            color: $white;
            outline: none;
            &:hover {
              background-color: $pdc-hover-color;
            }
						#company-name {
							color: $white;
						}
          }
					#company-name {
						color: $pdc-body-copy;
			      float: right;
			      font-size: 0.750rem;
			    }
        }
				div.noLink {
					padding: 15px 20px;
          text-transform: uppercase;
				}
        &.is-accordion-submenu-parent {
          a {
            &::after {
							border:none;
              content: '\0020\f078';
							display:inline;
              font-family: $font-awesome-family;
              font-weight: 900;
							left:auto;
							margin-top:0;
							position:relative;
							right:auto;;
							top:auto;
            }
          }
        }
        &.is-accordion-submenu-item {
          a {
            padding-left: 30px;
            &::after {
              content: '\0020';
              font-family: $font-awesome-family;
            }
          }
					&.is-accordion-submenu-parent {
						> a {
	            &::after {
	              content: '\0020\f078';
	              font-family: $font-awesome-family;
	            }
	          }
						li a {
							padding-left: 40px;
						}
					}
        }
      }
    }
  }
}
input.companySearch, #emulateId {
  @include breakpoint(small only) {
    //display: none;
    margin: 10px auto 10px 20px;
    width: 93%;
  }
}
