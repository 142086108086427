#messagesEditor {
	#editor {
		#tabsLists {
			padding-top:0;
			h5:first-of-type {
				margin-top:0;
			}
			.dropdown-pane.void {
				background:$pdc-body-copy;
				border:none;
				button {
					&.secondary {
						background:$pdc-grey;
						border-color:$pdc-lighter-copy;
						color:$pdc-base-color;
						&:active, &:focus, &:hover {
							background:$white-smoke;
							border-color:$white-smoke;
							outline:none;
						}
					}
				}
			}
			.variables {
				margin-bottom:1rem;
				position:relative;
				.showMessageVariables {
					font-size:0.750rem;
					line-height:1.8;
					position:absolute;
					right:0;
					top:0;
				}
			}
		}
	}
	#preview {
		&.noEditor {
			margin-left:0;
		}
    a {
			color:#0000EE;
		}
		div#formBlocker {
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 100;
		}
		#emailMessage {
			margin:40px;
			header {
				background:$pdc-grey;
				border-radius:$global-radius $global-radius 0 0;
				line-height:2.5rem;
				padding:0 10px;
			}
			section {
				border:1px solid $pdc-grey;
				border-radius:0 0 $global-radius $global-radius;
				padding:2rem;
			}
			#emailMessagePreview {
				font-family:Arial, Helvetica, sans-serif;
        #emailBodyPreview {
          white-space:pre-line;
        }
			}
		}
		#smsMessage {
			margin:0 40px 40px;
			footer {
				padding:10px;
			}
			header {
				background:$pdc-grey;
				border-radius:$global-radius $global-radius 0 0;
				line-height:2.5rem;
				padding:0 10px;
			}
			section {
				border:1px solid $pdc-grey;
				border-radius:0 0 $global-radius $global-radius;
				padding:2rem;
			}
			#smsMessagePreview {
				font-family:Arial, Helvetica, sans-serif;
        #smsBodyPreview {
          white-space:pre-line;
        }
			}
			.message {
				background:$pdc-grey;
				border:none;
				border-radius:10px;
				display:block;
				font-size:0.875rem;
				margin-bottom:20px;
				padding:10px;
				position:relative;
				width:320px;
				&:after {
					content:'';
					position:absolute;
					bottom:0;
					left:0;
					width:0;
					height:0;
					border:10px solid transparent;
					border-top-color:$pdc-grey;
					border-bottom:0;
					border-left:0;
					margin-left:10px;
					margin-bottom:-10px;
				}
			}
		}
	}
}
body.explorer, body.edge {
  #messagesEditor {
  	#editor {
  		#tabsLists {
        .tabs-panel {
          padding-top:10px;
          & > div:last-child {
            margin-bottom:60px;
          }
        }
      }
    }
  }
}
