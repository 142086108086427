#cboxTitle, #cboxCurrent, #cboxPrevious, #cboxNext, #cboxClose { color: $white; }
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose, #cboxCurrent { text-indent: 0; }
#cboxTitle, #cboxCurrent, #cboxPrevious, #cboxNext, #cboxClose { top: -30px; }
#cboxCurrent {
  right: 90px;
  @include breakpoint(small only) {
		display: none !important;
	}
}
#cboxLoadingGraphic {
  z-index:101;
}
#cboxLoadingOverlay {
  z-index:100;
}

.input-field {
  label {
    span:not(.help) {
			// color: $alert-color;
		}
  }
  span {
    &.counter {
      color: $pdc-lighter-copy;
      font-size: 0.750rem;
			position:relative;
    }
  }
  textarea {
		line-height:1.5;
		margin-bottom: 0;
	}
  .counter {
    display: block;
    margin-top: -20px;
    margin-left: 5px;
    padding-bottom: 20px;
  }
}

.submit-box {
  margin-top: 20px;
  button {
		margin-bottom: 5px;
	}
  p {
    color: $pdc-body-copy;
    font-size: 0.750rem;
  }
}

#logodiv {
  img {
		margin-bottom: 20px;
	}
}

#pinLengthdiv, #allowSendPayordiv {
	margin-bottom: 15px;
}

.field-error-status {
	margin-top: -3px !important;
}

#cboxLoadingGraphic {
  background: none;
}

.pdc-colorbox-content {
  .grid-padding-x {
    &>.cell {
      padding-left:0.9375rem;
      padding-right:0.9375rem;
    }
    .grid-padding-x {
      margin-left:0;
      margin-right:0;
    }
  }
  .grid-padding-y {
    &>.cell {
      padding-bottom:0.9375rem;
      padding-top:0.9375rem;
    }
    .grid-padding-y {
      margin-bottom:-0.9375rem;
      margin-top:-0.9375rem;
    }
  }
}
