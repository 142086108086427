.newForm, #pdcForm .newForm, #preview #pdcForm .newForm {
	h5 {
		margin-bottom:0.625rem;
	}
	section {
		margin-bottom:2rem;
	}
  #accountNumberDuplicateMsg {
    display:none;
    div {
      border:1px solid $primary-color;
      font-size:rem-calc(12px);
      padding:rem-calc(10px);
    }
  }
  .input-field {
		&.align-top {
			label {
				margin-top:0.625rem;
			}
		}
		button {
			&.small {
				align-self:flex-end;
				height:30px;
				margin-bottom:0;
			}
		}
		label {
			font-size:1rem;
			font-weight:bold;
			padding-left:1.25rem;
			text-transform:none;
		}
		[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], input, textarea {
			background:transparent;
			border:none;
			border-bottom:$input-border;
			border-radius:0;
			box-shadow:none;
			margin-bottom:0;
      padding:0.625rem;
      &:active, &:focus {
        border-color:$pdc-body-copy;
      }
      &:hover {
        border-color:$pdc-hover-color;
      }
      &:disabled, &:read-only {
				border:none;
				font-style:italic;
			}
		}
		textarea {
			border-bottom:none;
			border-left:$input-border;
			border-right:$input-border;
			padding-bottom:1.4rem;
		}
    #locationIdNameLabel {
      flex:0 0 auto;
      margin-left:0.625rem;
      margin-right:0.625rem;
      width:calc(50% - 1.25rem);
      .cell {
        padding-left:0;
        padding-right:0;
      }
    }
		.cell {
			margin-left:0.625rem;
			margin-right:0.625rem;
      line-height:1.3;
			&.grid-x {
				margin-left:0;
				margin-right:0;
				width:50%;
			}
      p {
        line-height:1.3;
        margin:0;
        padding:0.625rem;
      }
		}
		.counter {
			margin-left:calc(50% + 0.625rem);
			padding-left:0.5rem;
			padding-bottom:0;
		}
		.expandingArea {
      margin-left:0;
			margin-top:0.625rem;
			&.active {
				& > textarea {
					position:absolute;
				}
			}
		}
		.field-error, .field-success {
			border-radius:$global-radius;
			margin-bottom:0;
			margin-left:1.25rem;
			margin-top:0;
			padding:0.625rem;
      width:calc(100% - 1.875rem);
      &.field-error-textarea {
        margin-top:0 !important;
      }
		}
		.pdc-helper-icon {
			float:right;
			font-size:0.875rem;
			line-height:1.3rem;
			margin-left:20px;
      margin-right:1px;
		}
		.pdc-required {
			&::before {
				margin-left:-0.625rem;
			}
		}
		.radio-group {
			input {
				position:absolute;
			}
			label {
				font-weight:normal;
        margin-top:0;
				padding-left:0;
				width:auto;
				span {
					background:transparent;
					border:none;
					border-radius:0;
					color:$pdc-body-copy;
					height:40px;
					line-height:normal;
					padding:0.625rem;
					&:hover {
						color:$pdc-hover-color;
					}
				}
				input:checked + span {
					background:transparent;
					color:$pdc-body-copy;
					&:hover {
						background:transparent;
					}
				}
				&.label-left input:checked + span, &.label-left span {
					border:none;
					border-radius:0;
				}
			}
		}
		.select-after {
			&::after {
				right:0;
        top:14px;
			}
			&.readonly {
				&::after {
					color:$pdc-grey;
					display:none;
				}
        .select2-container {
          .select2-selection--single {
            border:none;
            font-style:italic;
          }
        }
			}
			select {
				background:transparent;
				border:none;
				border-bottom:$input-border;
				border-radius:0;
				box-shadow:none;
				line-height:1.25rem;
				margin:0;
				padding:0.625rem 1.5rem 0.625rem 0.625rem;
        &:active, &:focus {
          border-color:$pdc-body-copy;
        }
        &:hover {
          border-color:$pdc-hover-color;
        }
				&:disabled {
					border:none;
					font-style:italic;
				}
			}
      .select2-container {
        margin:0;
        .select2-selection--single {
          appearance:none;
          background:transparent;
          border:none;
          border-bottom:$input-border;
          border-radius:0;
          box-shadow:none;
          box-sizing:border-box;
          color:$input-color;
          display:block;
          font-family:$input-font-family;
          font-size:$input-font-size;
          font-weight:$input-font-weight;
          // height:40px;
          line-height:1.25rem;
          padding:0.625rem 1.5rem 0.625rem 0.625rem;
          transition:$input-transition;
          width:100%;
          &:active, &:focus {
            background:transparent;
            border:none;
            border-bottom:$input-border;
            border-color:$pdc-body-copy;
            box-shadow:none;
            outline:none;
          }
          &:hover {
            background:transparent;
            border-color:$pdc-hover-color;
          }
  				&:disabled {
  					border:none;
  					font-style:italic;
  				}
          .select2-selection__rendered {
            padding:0;
          }
        }
      }
      .select2-container--default {
        .select2-selection--single{
          .select2-selection__arrow {
            display:none;
          }
          .select2-selection__rendered {
            color:inherit;
            line-height:inherit;
          }
        }
      }
		}
	}
}
.select2-container--open {
  .select2-dropdown {
    min-width:100px;
    background-color:$input-background;
    border:$input-border;
    border-radius:0;
    &.select2-dropdown--above {
      border:$input-border;
    }
    .select2-results {
      .select2-results__options {
        .select2-results__option {
          padding:$input-padding;
          &.select2-results__option--highlighted[aria-selected] {
            background:$pdc-base-color;
            color:$white;
          }
        }
      }
    }
    .select2-search--dropdown {
      padding:$input-padding;
      .select2-search__field {
        border:$input-border;
        padding:$input-padding;
        margin:0;
      }
    }
  }
}
#div-schedule-timeoutMinute, #timeoutMinutediv {
  div.cell.grid-x {
    div.cell.small-6 {
      div.select-after {
        margin-left:0;
      }
      input.cell.small-6 {
        margin-left:0;
      }
    }
  }
}
