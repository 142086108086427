#ajaxLoadDiv {
  .grey-line { margin-bottom: 15px; }
  #templateRadiodiv.input-field {
    label:first-child {
      width: 100%;
      span {
        background-color: $pdc-background-color;
        border-color: transparent;
        border-style: none;
        border-width: 0;
        display: inline;
        padding: 0;
        &:hover { background-color: $pdc-background-color; }
      }
    }
    .radio-group {
      label {
        span {
          background-color: $white-smoke;
          @include border-1-solid-grey;
          display: block;
          padding: 8px 15px;
          &:hover { background-color: $white; }
        }
      }
    }
  }
}

.pdc-dashboard {
	aside {
    .color-container {
      background-color: $white-smoke;
      margin-top: 3px;
      padding: 5px 15px;
    }
    h5 {
      color: $pdc-body-copy;
      font-size: 1.000rem;
    }
  }
	#ajaxLoadDiv {
    .submit-box .submit-buttons {
      #resetButton {
        margin-inline:0;
      }

      @include breakpoint(small only) {
        #resetButton,
        #submitButton,
        #fuseButton,
        #dashboardSubmitDropdownSubmit {
          width: 100%;
          margin-inline:0;
        }
      }
    }

		#flowForm {
      .file-requests-container,
      #file-requests-container {
        width: 100%;
        .file-request {
          width:100%;
          .request-num {
            max-height: 81px;
            display:flex;
            justify-content:center;
            align-items:flex-end;
            p {
              margin-bottom:8px;
            }
          }
        }
      }

      #submitButton {
        width: 100%;
        margin: 0;
      }

      .recipient-controls,
      .file-request-controls {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      &.grid-margin-x, &.grid-margin-y, &.grid-padding-x, &.grid-padding-y {
				.grid-margin-x, .grid-margin-y, .grid-padding-x, .grid-padding-y {
					margin:0;
				}
			}
			#div-flow-timeoutSelector, #div-flow-cardExpYear {
				margin-top:21px;
			}
			.field-error, .field-success {
				margin:0;
				&.field-error-upload {
					margin-top:0 !important;
				}
        &.secureEntry {
        	margin-top: -1px;
        }
			}
			.grey-line {
				margin:0;
				padding:0;
			}
			.image-info {
				margin:0;
			}
			.input-field {
				.secureOverlay {
					margin:0;
				}
			}
      div.input-field {
        .select2-container {
          margin:0;
        }
      }
			p {
				margin:0;
				&.pdc-required {
					margin-top:10px;
				}
			}
			.pdc-checkboxes, .radio-group {
				margin:0;
			}
			[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], select, textarea {
				margin:0;
			}
			select {
				margin:0;
			}
      #dashboardSubmitDropdownSubmit {
        button {
          margin-bottom:16px;
        }
        .dropdown-pane {
          max-width:206.5px;
          a {
            text-align:left;
          }
        }
      }
		}
		#cardForm {
			.field-error, .field-success {
				margin:0;
        &#ajaxStatusajaxFormMsg {
          margin-bottom:20px;
        }
				&.field-error-upload {
					margin-top:0 !important;
				}
        &.secureEntry {
        	margin-top: -1px;
        }
        &#bulkFileMsg {
          margin-top:39px;
        }
			}
			&.grid-margin-x, &.grid-margin-y, &.grid-padding-x, &.grid-padding-y {
				.grid-margin-x, .grid-margin-y, .grid-padding-x, .grid-padding-y {
					margin:0;
				}
			}
      div.input-field {
        .select2-container {
          margin:0;
        }
      }
			[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
				margin:0;
			}
			p {
				margin:0;
				padding:0 0.625rem;
			}
			select, .radio-group {
				margin:0;
			}
		}
		#checkForm {
			.field-error, .field-success {
				margin:0;
				&.field-error-upload {
					margin-top:0 !important;
				}
        &.secureEntry {
        	margin-top: -1px;
        }
        &#bulkFileMsg {
          margin-top:39px;
        }
			}
			&.grid-margin-x, &.grid-margin-y, &.grid-padding-x, &.grid-padding-y {
				.grid-margin-x, .grid-margin-y, .grid-padding-x, .grid-padding-y {
					margin:0;
				}
			}
      div.input-field {
        .select2-container {
          margin:0;
        }
      }
			[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
				margin:0;
			}
			p {
				margin:0;
				padding:0 0.625rem;
			}
			select, .radio-group {
				margin:0;
			}
			#dateScheduled {
				position:relative;
				z-index:2;
			}
		}
		.paymentInput, .paymentStored, .cardSwipe {
			// width:40%;
			label {
				.labelNoEscape {
					a {
						margin-right:4px;
					}
				}
			}
		}
		#paymentTypediv {
			.radio-group {
				label {
					margin-bottom:1rem;
				}
			}
		}
		&.swiperActive {
			.paymentInput, .paymentStored, .cardSwipe {
				.input-field {
          #cardNumberToggleMsg {
            float: left;
            width: 100%;
          }
					.secureOverlay, #cardNumberToggle, #cardNumberSwipe {
						float:left;
						max-width:86%;
						margin-bottom:0;
					}
					.swipeButton {
						float:right;
						margin-top:4px;
					}
          .secureEntrySide {
            margin-right:17%;
          }
					@include breakpoint(small only) {
						.secureOverlay, #cardNumberToggle, #cardNumberSwipe {
							max-width:88%;
						}
            .secureEntrySide {
              margin-right:14%;
            }
					}
					@include breakpoint (medium only) {
						.secureOverlay, #cardNumberToggle, #cardNumberSwipe {
							max-width:82%;
						}
            .secureEntrySide {
              margin-right:22%;
            }
					}
				}
			}
		}
		#div-card-cardExpiration {
			// width:40%;
			> div {
				margin: 0 4% 0 0;
				width: 48%;
				&:last-child {
					margin:0;
				}
			}
		}
		.cardEntry {
			// width:20%;
		}
		.paymentInput.checkBankAccount, .paymentStored.checkBankAccount {
			width:50%;
		}
		.radio-group {
			margin:0 0 1rem 0;
		}
		@include breakpoint(small only) {
			.paymentInput, .paymentStored, .cardSwipe, #div-card-cardExpiration, .cardEntry {
				width:100%;
			}
			.paymentInput.checkBankAccount, .paymentStored.checkBankAccount {
				width:100%;
			}
		}
	}
  .pdc-trans-types {
    #otherTemplatesButtondiv {
      margin-top: 32px;
    }

    button {
      @include border-1-solid-grey;
      border-top: none;
      border-radius: 0;
      color: $pdc-body-copy;
      font-size: 0.750rem;
      margin: 0;
      padding: 12px 15px;
      width: 100%;
      text-align: left;
      text-transform: inherit;
      &:nth-child(2) {
        border-top-color: $pdc-grey;
        border-top-style: solid;
        border-top-width: 1px;
        border-top-left-radius: $global-radius;
        border-top-right-radius: $global-radius;
      }
      &:nth-child(5){
        border-bottom-left-radius: $global-radius;
        border-bottom-right-radius: $global-radius;
      }
      &:hover {
        background-color: $pdc-hover-color;
        color: $white;
      }
      &.active {
        background-color: $pdc-hover-color;
        color: $white;
      }
    }
    select {
      font-size: 0.750rem !important;
      padding-left: 15px;
    }
  }
  #ajaxForm {
    background-color: $white;
    padding: 12px;
  }
	.view-header {
    .grey-line { margin-bottom: 15px; }
  }
}

.recent-search {
  margin-top: 15px;
  margin-bottom: 15px;
  .active {
    &::before {
      content: '\f054\0020';
      font-family: $font-awesome-family;
      font-size: 0.750rem;
    }
    &:focus { outline: none; }
  }
  .pdc-btn {
    background-color: $white;
    @include border-1-solid-grey;
    border-left-width: 0;
    font-size: 0.850rem;
    padding: 10px 20px;
    &:hover, &:focus {
      background-color: $pdc-hover-color;
      color: $white;
    }
    &:first-child {
      border-top-left-radius: $global-radius;
      border-bottom-left-radius: $global-radius;
      border-left-width: 1px;
    }
    &:last-child {
      border-top-right-radius: $global-radius;
      border-bottom-right-radius: $global-radius;
      margin-left: -3px;
    }
    &.active {
      background-color: $pdc-base-color;
      color: $white;
      cursor: default;
    }
  }
}

.for-search {
  input[type="text"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .trans-search-button {
    background-color: $pdc-base-color;
    border-top-right-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
    border-color: $pdc-base-color;
    color: $white;
    display: block;
    padding: 7.5px 14px;
    &:hover {
      background-color: $pdc-hover-color;
      color: $white;
      i { color: $white; }
    }
  }
}

#achHelp {
	margin-bottom:0;
	td {
		vertical-align:top;
	}
}

#transactionResult.flowReceipt {
  #flowReceiptInfoContainer {
    margin: 0 auto 12px;
  }

	.flowReceiptContent {
    background-color: $white;
    margin: 0 auto 12px;
    padding: 16px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

		.flowReceiptUrl:not(:last-child) {
			margin-bottom:1rem;
		}

    #flowReceiptButtons {
      margin: 0 -16px;
    }
	}

	.flowReceiptInfo {
    background-color: $white;
		border:1px solid $pdc-grey;
		font-size:14px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    & > div:nth-child(odd) {
      background-color: #f1f1f1;
    }

    .flowReceiptName,
		.flowReceiptId {
			display:flex;
			div {
				padding:.5rem 1rem !important;
				&:first-child {
					font-weight:bold;
					width:34%;
				}
				&:last-child {
					overflow-wrap: break-word;
				  word-wrap: break-word;
				  -webkit-hyphens: auto;
				  -ms-hyphens: auto;
				  -moz-hyphens: auto;
				  hyphens: auto;
					width:66%;
				}
			}
		}
		.flowReceiptUrl {
			display:flex;
			div {
				padding:.5rem 1rem !important;
				&:first-child {
					font-weight:bold;
					width:34%;
				}
				&:last-child {
					overflow-wrap: break-word;
				  word-wrap: break-word;
					width:66%;
					code {
						display:block;
						padding:1rem;
						border:1px solid rgba(10,10,10,.25);
						border-radius:3px 3px 0 0;
						background-color:#fff;
						color:#666;
					}
					a.button {
						border-radius:0 0 3px 3px;
					}
				}
			}
		}
	}
}

#dashboardTransactionInfo {
  background-color: $white;
  margin: 0 auto 12px;
  max-width: 700px;
  padding: 32px;

	#receiptEmail {
		display:flex;
		> div:first-child {
			flex-shrink: 1;
			min-width: 60%;
			width: 100%;
			input[type="email"] {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
				height:40px;
				position: relative;
				z-index: 2;
			}
		}
		> div:last-child {
			flex-shrink: 2;
			min-width: 40%;
			width: 100%;
			button {
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
#dashboardTransactionReceipt {
	section {
		.pushDown.formatting {
			background: #fefefe;
			border: 1px solid #d0d0d0;
			max-width: 100% !important;
			table {
				border: none !important;
				margin-bottom: 1rem !important;
				tbody {
					border: none;
				}
				td {
					padding: .5rem 1rem !important;
				}
			}
		}
	}
}
