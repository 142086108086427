td {
  a.ui-state-default.ui-state-hover { color: $white; }
  a.ui-state-default.ui-state-active.ui-state-hover { color: $white; }
}

.ui-timepicker-div .ui-widget-header { margin-bottom: 8px; }
.ui-timepicker-div dl { text-align: left; }
.ui-timepicker-div dl dt { float: left; clear:left; padding: 0 0 0 5px; }
.ui-timepicker-div dl dd { margin: 0 10px 10px 40%; }
.ui-timepicker-div td { font-size: 90%; }
.ui-tpicker-grid-label { background: none; border: none; margin: 0; padding: 0; }
.ui-timepicker-div .ui_tpicker_unit_hide{ display: none; }

.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input { background: none; color: inherit; border: none; outline: none; border-bottom: solid 1px #555; width: 95%; }
.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input:focus { border-bottom-color: #aaa; }

.ui-timepicker-rtl{ direction: rtl; }
.ui-timepicker-rtl dl { text-align: right; padding: 0 5px 0 0; }
.ui-timepicker-rtl dl dt{ float: right; clear: right; }
.ui-timepicker-rtl dl dd { margin: 0 40% 10px 10px; }

/* Shortened version style */
.ui-timepicker-div.ui-timepicker-oneLine { padding-right: 2px; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time,
.ui-timepicker-div.ui-timepicker-oneLine dt { display: none; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time_label { display: block; line-height: 32px; padding-top: 2px; }
.ui-timepicker-div.ui-timepicker-oneLine dl { text-align: right; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd,
.ui-timepicker-div.ui-timepicker-oneLine dl dd > div { display:inline-block; margin:0; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_minute:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_second:before { content:':'; display:inline-block; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_millisec:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_microsec:before { content:'.'; display:inline-block; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide,
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide:before{ display: none; }

.ui-slider-horizontal .ui-slider-handle { top: -2px; }

#ui-datepicker-div {
	padding: 0;
  z-index:9999 !important;
	select {
		margin: 0;
		height: 2rem;
		padding: 0 25px 0 0.5rem;
	}
	table {
		background: transparent;
    margin-left: 2%;
    width: 96%;
		margin-bottom: 1rem;
		thead, tfoot {
			background: transparent;
			border: none;
		}
		tbody {
			background: transparent;
			border: none;
			tr:nth-child(even) {
				background: transparent;
			}
			td {
				a {
					text-align: center;
				}
			}
		}
	}
	.ui-datepicker-header {
		border-radius: 0;
		border: none;
		border-bottom: 1px solid $pdc-grey;
	}
	.ui-datepicker-prev {
		background: $pdc-base-color;
		border-radius: 0;
		border-right: 1px solid $pdc-grey;
		height: 100%;
		left: 0;
		top: 0;
		.ui-icon {
			color: $white;
		}
		&.ui-datepicker-prev-hover {
			background: $pdc-hover-color;
			border: none;
			border-right: 1px solid $pdc-grey;
			color: $white;
			font-weight: bold;
		}
	}
	.ui-datepicker-next {
		background: $pdc-base-color;
		border-left: 1px solid $pdc-grey;
		border-radius: 0;
		height: 100%;
		right: 0;
		top: 0;
		.ui-icon {
			color: $white;
		}
		&.ui-datepicker-next-hover {
			background: $pdc-hover-color;
			border: none;
			border-left: 1px solid $pdc-grey;
			color: $white;
			font-weight: bold;
		}
	}
	.ui-datepicker-title {
		&::before {
			color: #666666;
	    content: "\f078";
	    cursor: pointer;
	    font-family: $font-awesome-family;
	    font-size: 0.600rem;
	    pointer-events: none;
	    position: absolute;
	    top: 5px;
	    right: 52%;
		}
		&::after {
			color: #666666;
			content: "\f078";
			cursor: pointer;
			font-family: $font-awesome-family;
			font-size: 0.600rem;
			pointer-events: none;
			position: absolute;
			top: 5px;
			right: 20%;
		}
	}
	.ui-timepicker-div.ui-timepicker-oneLine {
		padding: 0 2%;
		.ui_tpicker_time_label {
			padding: 0;
		}
	}
	.ui_tpicker_hour_slider, .ui_tpicker_minute_slider {
	  position: relative;
	  &::after {
	    color: $pdc-body-copy;
	    content: "\f078";
	    cursor: pointer;
	    font-family: $font-awesome-family;
	    font-size: 0.600rem;
      font-weight: 900;
	    pointer-events: none;
	    position: absolute;
	    top: 9px;
	    right: 10px;
	  }
	}
	.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_minute:before, .ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_second:before {
		padding: 0 4px;
	}
	.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
		background: $pdc-base-color;
		border-color: $pdc-base-color;
		color: $white;
		&:hover, &:focus, &:active {
			background: $pdc-hover-color;
		}
	}
	.ui-datepicker-buttonpane button {
		font-size: 0.9rem;
		margin: 0.5rem 0.2rem;
		padding: 0.5rem 0.9rem;
		&.ui-priority-secondary {
			filter: Alpha(Opacity=100);
			opacity: 1;
		}
	}
}



.ui-datepicker-buttonpane {

}
