@import 'jquery-ui';

.flex {
  display: flex;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-end {
  justify-content: flex-end;
}

.fontPrimary {
	color:$primary-color;
}
.fontSecondary {
	color:$secondary-color;
}
.fontSuccess {
	color:$success-color;
}
.fontWarning {
	color:$warning-color;
}
.fontAlert {
	color:$alert-color;
}

html, body {
	height:100%;
}

#wrapper {
  min-height: 100%;
  position: relative;
}

#sliderContentContainer {
  height:calc(100% - 75px);
  margin-top:75px;
  &.position-right {
    @include breakpoint (small only){
      width:90%;
    }
  }
  &:has(.legacyBackground) {
    background-color:$pdc-background-color;
  }
  #sliderContent {
    padding:0 10px 10px;
  }
}

#sliderContentClose {
  left:3%;
  position:fixed;
  text-align:left;
  top: 85px;
  width:fit-content;
  z-index:50;
}
.closeButton {
  color:$white;
  font-size:1.2rem;
  &:hover {
    color:$white-smoke;
  }
}

.button.dropdown, .button-small.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: none;
  }
}
.dropdown-pane:not(.pdc-help) {
  background:$white;
  padding:0;
  ul {
    list-style-type:none;
    margin:0;
    padding:0;
    li {
      width:100%;
      a {
        display:block;
        padding:0.5rem 1rem;
        &:active, &:focus, &:hover {
          background:$pdc-hover-color;
          color:$white;
        }
      }
    }
  }
}

.body-copy-color {
  color: $pdc-body-copy;
}

@import 'header';

main.columns {
  margin-bottom: 130px;
}
@include breakpoint(small only) {
	main.columns {
		margin-bottom: 185px;
	}
}

a {
  color: $pdc-base-color;
  text-decoration: none;
  transition: 0.2s;

  &:hover,
  &:not(.button):hover,
  &:focus {
    color: $pdc-hover-color;
  }
  &:focus {
    outline: 1px dotted $pdc-body-copy;
  }
}

i {
  font-style:italic;
}

button, .button {
	cursor:pointer;
  @include breakpoint(small only){
    margin:0;
    width:100%;
  }
}

button, input, optgroup, select, textarea {
	font-family:$body-font-family;
}

table {
	border-collapse:collapse;
	border-spacing:0;
  &.alignContentTop td {
    vertical-align:top;
    i {
      padding-right:5px;
    }
  }
}

textarea {
  padding-bottom: 20px;
  resize: none;
	&:hover {
		background-color: lighten($pdc-base-color, 62%);
		border-color: $pdc-hover-color;
	}
}

input, select {
  font-size: 1.000rem;
  &:focus {
    border-color: $pdc-hover-color;
    outline-color: none;
    outline-style: none;
    outline-width: none;
  }
}

input {
  &[type="text"], &[type="email"], &[type="password"], &[type="tel"], &[type="url"] {
    color: $pdc-body-copy;
		height:40px;
    &:hover {
      background-color: lighten($pdc-base-color, 62%);
      border-color: $pdc-hover-color;
    }
  }
  &[type="search"] {
    &:focus {
      border-color: $pdc-grey !important;
    }
  }
  &[type="radio"] {
  }
  &[readonly]{
    background-color: $pdc-background-color;
    border: 1px solid $pdc-grey;
    box-shadow: none;
    &:focus, &:hover {
      background-color: $pdc-background-color;
      border: 1px solid $pdc-body-copy;
      box-shadow: none;
    }
  }
}

select {
  cursor: pointer;
  background-color: $white;
  background-image: none;
  background-size: 0;
  @include border-1-solid-grey;
  color: $pdc-body-copy;
	height:40px;
  padding-right: 25px;
  &:hover {
    background-color: lighten($pdc-base-color, 62%);
    border-color: $pdc-hover-color;
  }
  &:focus {
    border: 1px solid $pdc-body-copy;
    box-shadow: 0 0 5px #cacaca;
    outline: none;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			background-color: $white;
		}
		@supports (-ms-accelerator:true) {
			background-color: $white;
		}
		option {
			&:checked {
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					background-color: $pdc-body-copy;
					color: $white;
				}
				@supports (-ms-accelerator:true) {
					background-color: $pdc-body-copy;
					color: $white;
				}
			}
	  }
  }
  &:disabled {
    background-color: $pdc-background-color;
    border-color: $pdc-grey;
    &:hover {
      background-color: $pdc-background-color;
      border-color: $pdc-body-copy;
    }
  }
  option {
    background-color: $white;
    color: $pdc-body-copy;
		&:hover, &:focus, &:active, &:visited, &:checked {
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background-color: $pdc-body-copy;
				color: $white;
			}
			@supports (-ms-accelerator:true) {
				background-color: $pdc-body-copy;
				color: $white;
			}
		}
  }
}

.select-after {
  position: relative;
  &::after {
    color: $pdc-body-copy;
    content: "\f078";
    cursor: pointer;
    font-family: $font-awesome-family;
    font-size: 0.600rem;
    font-weight: 900;
    pointer-events: none;
    position: absolute;
    top: 13px;
    right: 10px;
		z-index: 2;
  }
}

.pdc-colorbox-content {
  background-color: $pdc-background-color;
  margin-top: 20px;
  margin-bottom: 20px;
	@at-root iframe#{&} {
		display: block;
		margin-bottom: 0;
		margin-top: 0;
	}
  table {
    margin-bottom: 20px;
  }
}

.button {
  &:focus {
    outline: 2px solid $white;
  }
}

.button-small {
  background-color: $pdc-base-color;
  border-radius: $global-radius;
  color: $white;
  font-size: 0.750rem;
  padding: 10px 15px;
  &:hover, &:active {
    background-color: $pdc-hover-color;
    color: $white !important;
  }
	&.disabled {
		background-color: get-color(disabled);
		cursor: default;
		&:hover, &:active {
			background-color: get-color(disabled);
		}
	}
}

#requiredTextContainer, #requiredCardTextContainer, #requiredCheckTextContainer, #requiredLegalContainer, #duplicateContainer {
  box-sizing:border-box;
  float:left;
  width:100%;
  padding:1.25rem 0.625rem;
  #requiredText, .requiredContainer {
    align-items:center;
    border-color:#cccccc;
    border-radius:3px;
    border-style:solid;
    border-width:1px;
    box-sizing:border-box;
    clear:both;
    display:flex;
    padding:10px;
    position:relative;
    #acknowledgediv, #allowDuplicatediv, #dialogIdCheckboxdiv {
      height:100%;
      left:0;
      margin:0;
      position:absolute;
      top:0;
      width:100%;
      z-index:1;
      .pdc-checkboxes {
        align-items:center;
        display:flex;
        flex-grow:1;
        flex-direction:column;
        height:100%;
        left:0;
        margin-bottom: 0;
        top:0;
        position:absolute;
        .pdc-check-item {
          align-items:center;
          display:flex;
          flex-grow:1;
          height:100%;
          input {
            display: none;
          }
          input:checked + label {
            background:transparent;
            color:$black;
            &:before {
              color:$success-color;
              content:"\f14a";
            }
          }
          label {
            border:none;
            font-size:2rem;
            height:100%;
            &:hover {
              background:transparent;
              color:$black;
            }
            &::before {
              content:"\f096";
              font: var(--fa-font-regular);
              display:block;
              height:14px;
              line-height:14px;
              margin-top:-6px;
              position:relative;
              top:50%;
              width:14px;
            }
          }
          .labelRaw {
            display:none;
          }
        }
      }
      #acknowledgeMsg, #dialogIdCheckboxMsg {
        left:0;
        margin-left:0;
        margin-top:1px;
        position:absolute;
        top:100%;
        width:100%;
      }
    }
    .requiredText {
      position:relative;
      z-index:2;
      left:45px;
      width:calc(100% - 45px);
      p {
        margin-bottom:0;
        padding:0 !important;
      }
      #customTextOutput {
        margin-top:$global-margin;
      }
    }
  }
  &.paypalSpecialPadding {
    padding-left:0.9375rem;
  }
}

a.disabled {
  cursor: not-allowed;
  opacity: .25;
  &:hover, &:active {
    opacity: .30;
  }
}

.button.buttonSecondary {
  background-color:$white;
  border:thin solid $pdc-base-color;
  color:$pdc-base-color;
  &.buttonSecondary:hover {
   background-color:$pdc-hover-color;
   border:thin solid $pdc-base-color;
   color:$white;
 }
}

label {
  color: $pdc-body-copy;
  font-size: 0.750rem;
  text-transform: uppercase;
  & + p {
    color: $pdc-body-copy;
    font-size: 1.000rem;
    margin-bottom: 5px;
  }
  &.checkbox {
    text-transform: capitalize !important;
  }
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
div.dataTables_filter label input:-ms-input-placeholder {
  color: #cacaca !important;
}

section {
  &.view-header {
    div.header-space {
      background-color: rgba($white, 0.75);
      margin-bottom: 5px;
			margin-top: 20px;
      padding: 10px 20px 5px 20px;
      h2 {
        color: $pdc-body-copy;
        font-size: 1.100rem;
        i.fa-info {
          background-color: $pdc-base-color;
          border-radius: $global-radius;
          color: $white;
					font-size: 1.000rem;
          padding: 5px 10px;
          &:hover {
            background-color: $pdc-hover-color;
            cursor: pointer;
          }
        }
      }
      div, p {
				border-top: 1px solid $pdc-grey;
        color: $pdc-body-copy;
        display: none;
        font-size: 0.750rem;
        padding-top: 10px;
      }
    }
  }
}

.grey-line {
  padding-top: 10px;
  border-bottom-color: $pdc-grey;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 5px;
}

#backToTop {
  color: $pdc-base-color;
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: none;
  z-index:2;
  @include breakpoint(small only){
    bottom: -150px;
  }
}

#g-recaptcha-responsediv {
	overflow:hidden;
	width:100%;
	.g-recaptcha {
	  min-height:79px;
		.input-field & {
			iframe {
				height: auto !important;
			}
		}
	}
	.field-error-textarea {
		margin-top:-5px !important;
		position:relative;
	}
}

.field-error-standalone {
  background-color: $alert-color;
  border-radius: $global-radius;
  color: $white;
  font-size: 0.750rem;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
}

.button, .button.primary {
  background-color: $pdc-base-color;
  &:hover {
    background-color: $pdc-hover-color;
  }
  &[type="submit"] {
    margin-right:5px;
    margin-left:5px;
  }
}

.pdc-upload-btn {
  overflow: hidden;
  max-width: 100%;
	position:relative;
  input {
    position: absolute;
    left: -9999px;
  }
  span {
    background-color: $white-smoke;
    @include border-1-solid-grey;
    border-radius: $global-radius;
    color: $pdc-body-copy !important;
    cursor: pointer;
    display: block;
    padding: 8px 15px;
    text-transform: none;
    word-wrap: break-word;
    line-height: 2;
    max-height: 40px;
		position:relative;
    &:hover {
      background-color: $pdc-hover-color;
      color: $white !important;
    }
    &::before {
      content: '\f093\0020';
      font-family: $font-awesome-family;
      font-weight: 900;
    }
		&.clearFileSelection {
			align-items:center;
			background:$white-smoke;
			border-left:none;
			border-radius:0 $global-radius $global-radius 0;
			color:$pdc-base-color;
			display:flex;
			height:40px;
			justify-content:center;
			padding:0;
			position:absolute;
			right:0;
			text-align:center;
			top:0;
			width:40px;
			z-index:10;
			&::before {
				content:'';
			}
			&:hover {
				color:$pdc-hover-color !important;
			}
		}
  }
  input:disabled + span {
    background-color: $pdc-background-color;
    cursor: default;
    &:hover {
      background-color: $pdc-background-color;
      color: $pdc-body-copy !important;
    }
  }
}
.input-field {
	.radio-group {
	  label {
	    float: left;
	    overflow: auto;
	    span {
	      background-color: $white-smoke;
	      border: 1px solid $pdc-grey;
	      border-left-width: 0;
	      color: $pdc-body-copy;
	      display: block;
	      padding: 8px 15px;
	      text-transform: capitalize;
	      &:hover {
	        background-color: $pdc-hover-color;
	        color: $white;
	      }
	      &::before {
	        content: '\f10c\0020';
          font-family: $font-awesome-family;
	      }
				&:not(.help) {
					color:$pdc-body-copy;
					&:hover {
						color:$white;
					}
				}
	    }
	    input {
	      position: absolute;
	      left: -9999px;
	    }
	    input:checked + span {
	      background-color: $pdc-body-copy;
	      border-color: $pdc-grey;
	      color: $white;
	      &:hover {
	        background-color: $pdc-body-copy;
	        border-color: $pdc-grey;
	        cursor: default;
	      }
	      &::before {
	        content: '\f192\0020';
	        font-family: $font-awesome-family;
	      }
	    }
	    &.label-left input:checked + span, &.label-left span {
	      border-top-left-radius: $global-radius;
	      border-bottom-left-radius: $global-radius;
	      border-left: 1px solid $pdc-grey;
	    }
	    &.label-right input:checked + span, &.label-right span {
	      border-top-right-radius: $global-radius;
	      border-bottom-right-radius: $global-radius;
	    }
	    input:disabled + span {
	      background-color: $pdc-background-color;
	      border-color: $pdc-grey;
	      &:hover {
	        background-color: $pdc-background-color !important;
	        border-color: $pdc-body-copy !important;
	        color: $pdc-body-copy !important;
	        cursor: default;
	      }
	    }
	    input:checked:disabled + span {
	      background-color: $pdc-grey;
	      &:hover, &:focus {
	        background-color: $pdc-grey !important;
	        color: $white !important;
	      }
	    }
	  }
	}
}

.field-error {
  background-color: $alert-color;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: $global-radius;
  border-bottom-right-radius: $global-radius;
  color: $white;
  display: block;
  font-size: 0.750rem;
  margin-top: -17px;
  margin-bottom: 20px;
  padding: 10px 15px;
  &::before {
    content: "\f071 \00a0";
    font-family: $font-awesome-family;
    font-weight: 900;
  }
  a {
    color:$white;
    text-decoration:underline;
    &:active, &:focus, &:hover {
      color:$black;
    }
  }
}
.field-success {
  background-color: $pdc-base-color;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: $global-radius;
  border-bottom-right-radius: $global-radius;
  color: $white;
  display: block;
  font-size: 0.750rem;
  margin-top: -17px;
  margin-bottom: 20px;
  padding: 10px 15px;
  a {
    color:$white;
    text-decoration:underline;
    &:active, &:focus, &:hover {
      color:$medium-gray;
    }
  }
}

.field-error-textarea {
	margin-top: -18px !important;
}

.field-error-upload {
	margin-top: 0px !important;
}

.pdc-check-item {
  input {
		display: none;
	}
  label {
		display: inline-block;
	}
  input + label {
    border-top: 1px solid $pdc-grey;
    border-right: 1px solid $pdc-grey;
    border-bottom: none;
    border-left: 1px solid $pdc-grey;
    margin-left: 0;
    padding: 8px 15px;
    display: inline-block;
    position: relative;
    width: 100%;
    &:nth-child(2) {
      border-top-left-radius: $global-radius;
      border-top-right-radius: $global-radius;
    }
    &:last-child {
      border-bottom-left-radius: $global-radius;
      border-bottom-right-radius: $global-radius;
      border-bottom: 1px solid $pdc-grey;
    }
    &::before {
      content: "\f096 \00a0";
      font-family: $font-awesome-family;
    }
    &:hover {
      background-color: $pdc-hover-color;
      color: $white;
      cursor: pointer;
    }
  }
  input + label:active, input:checked + label:active { background-color: $pdc-base-color; }
  input:checked + label {
    background-color: $pdc-body-copy;
    color: $white;
    &::before {
      content: "\f14a \00a0";
      font-family: $font-awesome-family;
    }
    &:hover {
      background-color: $pdc-hover-color;
    }
  }
  input:disabled + label {
    background-color: $pdc-background-color;
    cursor: default;
    &:hover {
      color: $pdc-body-copy;
    }
  }
  input:checked:disabled + label {
    background-color: $pdc-grey;
    color: $white;
    cursor: default;
  }
  &.pdc-toggle-horizontal {
    display: flex;
    @include breakpoint (small only){
      display: inline;
    }
    input + label {
      background-color: $white-smoke;
      border-radius: 0;
      border-bottom: 1px solid $pdc-grey;
      border-left: none;
      display: inline;
      float: left;
      margin-right: 0;
      //width: 16.5%;
      @include breakpoint (small only){
        border-top: none;
        border-left: 1px solid $pdc-grey;
        width: 100%;
      }
      &:hover {
        background-color: $pdc-hover-color;
      }
      &:nth-child(2) {
        border-left: 1px solid $pdc-grey;
        border-top-left-radius: $global-radius;
        border-bottom-left-radius: $global-radius;
        @include breakpoint (small only){
          border-top: 1px solid $pdc-grey;
          border-top-left-radius: $global-radius;
          border-bottom-left-radius: 0;
          border-top-right-radius: $global-radius;
        }
      }
      &:last-child {
        border-top-right-radius: $global-radius;
        border-bottom-right-radius: $global-radius;
        @include breakpoint (small only){
          border-top-right-radius: 0;
          border-bottom-right-radius: $global-radius;
          border-bottom-left-radius: $global-radius;
        }
      }
    }
    input:checked + label {
      background-color: $pdc-body-copy;
      &:hover {
        background-color: $pdc-hover-color;
      }
    }
    input:checked:disabled + label {
      background-color: $pdc-grey;
      &:hover {
        background-color: $pdc-grey;
        color: $white;
      }
    }
    input:disabled + label {
      background-color: $pdc-background-color;
      &:hover {
        background-color: $pdc-background-color;
        color: $pdc-body-copy;
      }
    }
  }
}

.pdc-checkboxes {
	margin-bottom: 15px;
	label {
		vertical-align:top;
	}
  &.checkboxNestedDiv {
    .pdc-check-item {
      border:1px solid $pdc-grey;
      border-radius:$global-radius;
      > div:last-of-type {
        border:none;
      }
      > label {
        border:none;
        border-bottom:1px solid $pdc-grey;
        border-radius:0;
      }
      .parentCheckbox {
        border-bottom:1px solid $pdc-grey;
        input + label {
          border: none;
          margin: 0;
          width: calc(100% - 40px);
          &:nth-child(2) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
          &:last-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
          }
        }
        .childCheckbox {
          background:$white-smoke;
          padding:10px;
          label {
            width:100%;
          }
        }
        .hideShowClosest {
          background:$pdc-base-color;
          color:$white;
          display:inline-block;
          font-size:12px;
          line-height:1.8;
          padding:9px 0 8px 0;
          text-align:center;
          width:40px;
          &:active,&:focus,&:hover {
            background:$pdc-hover-color;
          }
        }
      }
    }
  }
}

.pdc-checkboxes.pdc-toggle-box {
  background-color: $white-smoke;
  @include border-1-solid-grey;
  border-radius: $global-radius;
  padding: 7px 10px;
  margin-bottom: 1rem;
  .pdc-check-item {
    input {
      display: none;
    }
    label {
      display: inline-block;
    }
    input + label {
      background-color: $white-smoke;
      border-color: $white-smoke;
      border-width: 0;
      border-bottom: $white-smoke;
      margin-left: 0;
      padding: 0;
      text-transform: inherit;
      display: inline-block;
      position: relative;
      width: auto;
      &:nth-child(2) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      &:last-child {
        border-bottom-color: $white-smoke;
        border-bottom-width: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &::before {
        content: "\f096 \00a0";
        font-family: $font-awesome-family;
      }
      &:hover {
        background-color: $white-smoke;
        color: $pdc-hover-color;
        cursor: pointer;
      }
    }
    #pdc-select-all { background-color: $white-smoke; }
    input + label:active, input:checked + label:active, input:checked + label#pdc-select-all { background-color: $white-smoke; }
    input:checked + label {
      background-color: $white-smoke;
      border-color: $white-smoke;
      color: $pdc-body-copy;
      &::before {
        content: "\f14a \00a0";
        font-family: $font-awesome-family;
      }
      &:last-child {
        border-bottom-color: none;
      }
      &:hover {
        background-color: none;
      }
    }
  }
}

.pdc-checkboxes-horizontal {
  @include breakpoint (small only) {
    margin: 10px auto;
  }
}

.pdc-cheque-options {
  margin-top: 20px;
  margin-bottom: 10px;
}

.error-404 {
  background-color: $alert-color;
  border-radius: $global-radius;
  color: $white;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 10px 15px;
  h2 {
    font-size: 1.250rem;
		margin:0;
  }
}

.error-secondary {
  background-color: $light-gray;
  border-radius: $global-radius;
  color: $alert-color;
  font-size: 0.90rem;
  margin-bottom: 10px;
  padding: 5px;
}

section.login-container {
  margin-top: 40px;
  p {
    margin-bottom: 5px;
  }
  button.button {
    width: 100%;
  }
}

.dropdown-pane {
  font-size: 1.00rem;
  &.top img {
    margin-bottom: 10px;
  }
	&.pdc-help {
		background-color: lighten($pdc-hover-color, 20%);
    @include border-1-solid-grey;
		font-size: 0.750rem;
		max-height: 200px;
		overflow: auto;
		text-transform: none;
		white-space: normal;
		z-index: 20;
    a {
			font-weight:bold;
			text-decoration:underline;
		}
		p {
			font-weight: 400;
	    margin: 0;
		}
    .helpImage {
      margin-top:10px;
      img {
        margin-bottom:0;
      }
    }
	}
}

.pdc-required {
	&::before {
		color: $alert-color;
		content: "\002A\0020";
		font-weight: bold;
	}
	&.before {
		&::after {
			content: "";
		}
		&::before {
			color: $alert-color;
			content: "\002A\0020";
			font-weight: bold;
		}
	}
	.useStoredValue {
		margin:0 3px 0 0;
	}
}

label {
  .dropdown-pane {
    background-color: lighten($pdc-hover-color, 20%);
    @include border-1-solid-grey;
    font-size: 0.750rem;
    z-index: 100;
    text-transform: none;
    p {
      font-weight: normal;
      margin: 0;
    }
  }
  &.label-left, &.label-right, &.label-center {
    cursor: pointer;
  }
}

.form-box {
  .dropdown-pane {
    max-height: 200px;
    overflow: auto;
		white-space: normal;
  }
}

.linkRefresh, .linkCustom, .linkDownload {
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
}

.linkSearch {
  display: inline-block;
  margin-top: 10px;
}

.image-info {
	display: block;
  color: $pdc-lighter-copy;
  font-size: 0.750rem;
	margin-bottom: 1rem;
	#documentIdMsg.field-error + & {
		margin-top: -14px;
	}
}

.rb-02 {
  width: 50%;
}
.rb-03 {
  width: 33.333333%;
}
.rb-04 {
  width: 25%;
}
.rb-05 {
  width: 20%;
}

.wordWrap {
  overflow-wrap:break-word;
  word-wrap:break-word;
  -ms-word-break:break-all;
  word-break:break-all;
  word-break:break-word;
  -ms-hyphens:auto;
  -moz-hyphens:auto;
  -webkit-hyphens:auto;
  hyphens:auto;
}

#groupLocations {
  .form-box {
    margin:0 20px;
    #locationAdd {
      [type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], textarea {
        margin:0;
      }
      .field-error {
        margin:0;
      }
    }
    #locationIdByGroup {
      #locationIdList {
        .locationListItem {
          border-top:$input-border;
          margin-top:20px;
          padding-top:20px;
          &.formBlocker {
            pointer-events:none;
            opacity:0.5;
            background:none !important;
            -webkit-user-select:none; /* Chrome all / Safari all */
            -moz-user-select:none; /* Firefox all */
            -ms-user-select:none; /* IE 10+ */
            user-select:none; /* Likely future */
          }
        }
        h6 {
          display:flex;
          b {
            flex:auto;
            padding-right:20px;
          }
          span {
            flex:0 0 auto;
          }
        }
      }
    }
  }
}

// Effective Settings Preview
#effectiveSettings {
  line-height:1.2;
  margin:0 auto;
  padding:20px;
  width:580px;
  label {
    font-size:1rem;
    font-weight:bold;
    text-transform:none;
  }
  section {
    h5 {
      margin:0 0 10px 0;
    }
  }
  #previewFooter {
    border-top:1px solid #cacaca;
    line-height:1;
    margin:20px 0 0 0;
    padding:20px 0 0 0;
    text-align:center;
    small {
      color:$pdc-body-copy;
      font-size:10px;
      margin:0 10px;
      .inherited {
        font-size:10px;
      }
    }
  }
  .inherited {
    color:$pdc-hover-color;
    font-weight:bold;
  }
  .input-field {
    margin-bottom:10px;
    margin-left:20px;

    div {
      margin-top: 4px;
    }
  }
}
.sectionSpacing {
  margin-top:40px;
}

#consentTextContainer {
  width:100%;
  padding:10px;
  #consentText {
    border:1px solid #cccccc;
    border-radius: 3px;
    padding:10px;
    position:relative;
    #pdcBillingConsentdiv {
      height:100%;
      .pdc-checkboxes {
        height:100%;
        .pdc-check-item {
          height:100%;
          input {
            display: none;
          }
          input:checked + label {
            background:transparent;
            color:$black;
            &:before {
              color:$success-color;
              content:"\f14a";
            }
          }
          label {
            border:none;
            font-size:2rem;
            height:100%;
            &:hover {
              background:transparent;
              color:$black;
            }
            &::before {
              content:"\f096";
              font: var(--fa-font-regular);
              display:block;
              height:14px;
              line-height:14px;
              margin-top:-6px;
              position:relative;
              top:50%;
              width:14px;
            }
          }
        }
      }
      #pdcBillingConsentMsg {
        left:0;
        margin:1px 0 0 0;
        position:absolute;
        top:100%;
        width:100%;
      }
    }
  }
}

#paymentReceiptIframe {
  border:none;
  width:100%;
  min-height:500px;
}

.hideToo {
  display:none !important;
}

.copyCode, .copyCodeTable {
  cursor:pointer;
  .tempCopy, .tempCopyButton {
    margin-left:10px;
  }
}

#mainContainer {
  padding:1rem;
  #mainContentWithJavascript {
    margin:0;
    .grid-x {
      width:100%;
    }
  }
  #subContentContainer {
    width:100%;
  }
  .noPadding {
    padding:0;
  }
  .noPaddingLeft {
    padding-left:0;
  }
  .noPaddingRight {
    padding-right:0;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-padding-y > .cell {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .grid-padding-x > .cell {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}

.grid-pdc .cell  {
  padding:20px 10px;
  border: thin solid $pdc-hover-color;
  background-color:#fff;
  p {
    color:$dark-gray;
  }
  .button {
    width:100%;
    margin-bottom:0;
    &.buttonInfo {
      background-color:#fff;
      color:#000;
      border-color:$pdc-hover-color;
      cursor:default;
    }
  }
  &.grid-top-section {
    border:none;
    padding-bottom: 0;
    padding-top:0;
  }
  &.grid-bottom-section {
    border:none;
    padding-top:0;
  }
}

#loaderPdc {
  font-size: 1.5rem;
  height: auto;
  margin: 25% auto;
  text-align: center;
  vertical-align: middle;
  .flip {
    --fa-animation-duration: 3s;
  }
}

.itemStatus {
  background-color:#d8b0e9;
  color:#000;
  font-size:0.75rem;
  font-weight:normal;
  height:fit-content;
  margin:5px 0;
  padding:3px 7px;
  width:fit-content;
}

.advertisement {
  border:thin solid $pdc-base-color;
  padding:5px;
  background-color:$white-smoke;
  .rightColumn {
    position:relative;
    min-height:120px;
    .bottomButton {
      position:absolute;
      bottom:0;
      left:0;
      padding:0 10px;
    }
  }
}

@import 'timepicker';
@import 'data-table';
@import 'spectrum';
@import 'forms';
@import 'users';
@import 'modals';
@import 'transactions';
@import 'footer';
@import 'dashboard';
@import 'messenger';
@import 'reporting';
@import 'formDesigner';
@import 'portal';
@import 'messages';
@import 'iframed';
@import 'print';
@import 'newForms';
@import 'schedules';
@import 'customer';
