@media print {
	* {
		color:$pdc-body-copy !important;
	}
	a {
		&:after {
			display:none;
		}
	}
	header#desktop-nav {
		height:auto !important;
		body#iframed & {
			display:block;
			section {
				height:77px !important;
				max-width:100% !important;
				width:100% !important;
			}
		}
		.sticky {
      &.is-anchored {
  			max-width:100% !important;
  		}
      &.is-stuck {
        max-width:100% !important;
        position:relative !important;
      }
    }
		.columns {
			padding:0;
			nav.top-bar {
				padding:1rem 0;
				.menu > li > a {
					padding:0;
					img {
						margin:0;
						max-width:150px !important;
					}
				}
			}
		}
		.header-logo {
			width:auto;
		}
	}
	section.view-header div div.header-space {
		padding:0;
		h2 {
			margin:0;
			.info-toggler {
				display:none;
			}
		}
	}
	main.columns {
		margin-bottom:0;
	}
  .pdc-dashboard {
    .form-box {
      #transactionResult {
        left:0;
        margin-top:2rem;
        width:100%;
        #dashboardTransactionReceipt {
          section {
            margin-left:15%;
            margin-top:0;
            width:70%;
            .pushDown.formatting {
              table {
                border-collapse:inherit;
                margin:0.5rem 0;
              }
            }
          }
        }
      }
    }
  }
	.portalReceiptContainer {
		#error-handler-container {
			section {
				margin-left:15%;
				width:70%;
			}
		}
		#pdcFormContainer {
			display:none;
		}
		#portalReceipt {
			margin-top:0;
			margin-bottom:0;
			section {
				margin-left:15%;
				margin-top:0;
				width:70%;
				.pushDown.formatting {
					table {
						border-collapse:inherit;
						margin:0.5rem 0;
					}
				}
			}
		}
	}
	.messenger {
		display:none;
	}
}
